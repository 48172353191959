// import './App.css';

import { useEffect, useRef, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import EatsEzy from "./components/ExploreWorks/EatsEzy";
import ExploreWorks from "./components/ExploreWorks/ExploreWorks";
import Navbar from "./components/Navbar/Navbar";
import AboutUs from "./pages/AboutUs";
import Industries from "./pages/Industries.js";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import IndustriesInner from "./components/Industries/IndustriesInner";
import IndustriesInnerBox from "./components/Industries/IndustriesInnerBox";
import IndustyInd from "./pages/IndustryIndi";
import Services from "./pages/services";
import ServiceInd from './pages/ServiceInd';
import MetaTags from 'react-meta-tags';
import StaffPage from "./pages/StaffPage";

function App() {

  const homeRef = useRef(null)
  const aboutRef = useRef(null)
  const industriesRef = useRef(null)
  const servicesRef = useRef(null)
  const ourWorksRef = useRef(null)
  
  const executeScroll = (str) => {
    str.current?.scrollIntoView({ behavior: "smooth" });
  };

  // const window = document.getElementsByClassName("body")[0]
  const [prevScrollpos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true)

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset

    if (currentScrollPos > prevScrollpos) {
      setVisible(false)
    } else {
      setVisible(true)
    }

    setPrevScrollPos(currentScrollPos)
  }

  // console.log(window.getElementsByClassName('body'))

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // console.log(visible)
    return () => window.removeEventListener('scroll', handleScroll)
  })

  return (
    <Router>
      <Navbar visible={visible}  executeScroll={executeScroll} servicesRef={servicesRef} industriesRef={industriesRef} homeRef={homeRef} aboutRef={aboutRef} />
        <Routes>
          <Route path="/"  element={<Home setVisible={setVisible} executeScroll={executeScroll} ourWorksRef={ourWorksRef} servicesRef={servicesRef} industriesRef={industriesRef} homeRef={homeRef} aboutRef={aboutRef} />} />
          <Route path="/about-us" element={<AboutUs executeScroll={executeScroll} setVisible={setVisible} />} />
          <Route path="/industries" element={<Industries  executeScroll={executeScroll} setVisible={setVisible} />} />
          <Route path="/industries/logisticandsupplychain" element={<IndustyInd executeScroll={executeScroll} setVisible={setVisible} img="logistics.jpeg" title='LOGISTICS & SUPPLY CHAIN' subtitle='Simplifying process of logistics & supply chain through technology'  detail="Logistics and warehousing come with their own set of challenges. One of the biggest challenges is ensuring that all stakeholders have visibility into the supply chain. Tools such as blockchain and real-time tracking can help increase transparency and improve collaboration among all parties, reducing the risk of supply chain disruptions. Additionally, inventory management is critical for logistics and warehousing companies. Companies can improve accuracy and efficiency in inventory tracking and forecasting through the use of automation and artificial intelligence, thereby reducing waste and increasing profitability. In addition, last-mile delivery is a hard problem to crack for most market players in the logistics and warehousing sector. The final delivery to customers can be a major pain point in logistics and warehousing. Companies can improve delivery times and accuracy by using technologies such as autonomous delivery vehicles and real-time tracking, thus providing customers with a better delivery experience. Thus, the use of IT and digitization can help logistics and warehousing companies overcome their technological challenges and stay ahead of the competition. By leveraging technology, companies can improve operations, increase efficiency, and ensure customer satisfaction, thus driving growth and success."/>}/>
          <Route path="/industries/foodandbeverages" element={<IndustyInd executeScroll={executeScroll} setVisible={setVisible} img="food-&-beverages.jpeg" title='FOOD & BEVERAGES' subtitle='Reimagining the food industry with design innovations' detail="The food and beverage industry is becoming increasingly competitive, as consumers have more options and higher expectations for the products they buy. Companies can use data analytics and AI to gain a deeper understanding of consumer preferences and behaviour, enabling them to create products that meet their needs and stand out from the competition. Furthermore, it is essential for food and beverage companies to ensure food safety and quality for their success. IT tools, such as food safety management systems and real-time monitoring of production processes, can help companies meet regulatory standards and provide consumers with high-quality products. Nowadays, it is becoming increasingly important for food and beverage companies to engage with customers in new and innovative ways. By utilizing design thinking, companies can create unique and memorable customer experiences that will increase brand loyalty and drive sales."/>}/>
          <Route path="/industries/healthcare" element={<IndustyInd executeScroll={executeScroll} setVisible={setVisible} img="healthcare.jpeg" title='HEALTHCARE'  subtitle='Simplifying process of logistics & supply chain through technology' detail='Digital transformation is conquering the healthcare industry. Artificial intelligence, robotics, big data, and other related technologies drive healthcare growth. These advances improve patient outcomes, simplify operations, and reduce costs. Artificial intelligence and robotics are used in medical analysis and surgery, while big data drives informed decision-making. The B2B model fosters collaboration and profitability. As a result, financial institutions are investing in health technology, and following COVID-19, all stakeholders are putting a lot of emphasis on health services.'/>}/>
          <Route path="/industries/retail" element={<IndustyInd executeScroll={executeScroll} setVisible={setVisible} img="Retail.jpg" title='RETAIL' subtitle='Empowering Agriculture with automating processes.' detail="In today's fast-paced and competitive marketplace, retail businesses face several major pain points. One of the biggest challenges in today's fast-paced and competitive marketplace is delivering a seamless and personalized shopping experience. For customers in the B2B and B2C market segments. Customers demand easy access to information, a fast and easy checkout process, and personalized recommendations and offers. To meet these challenges, retailers worldwide are using technology and data to stay competitive and deliver the best possible retail experience. Collecting and analyzing customer data, it enables retailers to run targeted marketing campaigns, streamline their supply chain processes, and improve their in-store and online customer experience. Artificial intelligence and machine learning algorithms are also being used to provide real-time insights and recommendations to customers so they can make informed purchasing decisions. In addition, the integration of advanced technologies such as augmented reality, virtual reality and omnichannel retailing provides new and innovative ways to interact with customers with brands and products. However, it is important for retailers to strike a balance between using technology and data to enhance the customer experience while protecting their privacy. Dealers must be aware of this. Adequate privacy and data protection policies are in place and they are transparent about how customer data is used. As a result, retailers face major challenges in delivering superior products and customer shopping experiences. Using technology and data, they can stay competitive and deliver the best retail experience while balancing customer privacy concerns."/>}/>
          <Route path="/industries/constructionAndRealEstate" element={<IndustyInd executeScroll={executeScroll} setVisible={setVisible} img="Construction & Real Estate.jpg" title='Construction & Real Estate' subtitle='Empowering Agriculture with automating processes.' detail="The construction and real estate industry is undergoing digital transformation, with a shift in focus to providing a seamless and enhanced customer experience in this highly competitive industry. In today's market, clients expect speed, convenience and personal service that traditional construction and real estate firms struggle to provide. To meet these challenges, companies must use technology and data to streamline their operations, improve communication and collaboration, and provide a more personalized experience to their customers. The use of virtual and extended reality technology is revolutionizing the way customers interact with real estate, providing them with a more immersive and interactive experience. However, technology adoption in the construction and real estate industry is still in its early stages, and companies must grapple with privacy and cybersecurity challenges. CXOs need to ensure they have strong data protection and privacy policies and are transparent about how they use customer data. The construction and real estate industry is at the forefront of digital transformation, using technology and data to provide opportunities to enhance customer experience, streamline operations and remain competitive in a rapidly evolving market."/>}/>
          <Route path="/industries/hospitality" element={<IndustyInd executeScroll={executeScroll} setVisible={setVisible} img="hospitality.jpg" title='Hospitality' subtitle='Empowering Agriculture with automating processes.' detail='One of the most significant challenges in the hospitality industry is rising labour costs. By implementing automation and self-service technology such as touch-screen ordering systems and mobile check-ins, hospitality businesses can reduce labour costs while still providing a seamless customer experience. These systems can also collect useful customer data, which can be analysed to improve operations and efficiency. Another issue is that traditional hospitality businesses are finding it increasingly difficult to attract and retain customers as online travel agencies and booking platforms grow in popularity. By leveraging digital marketing and social media, hospitality businesses can reach a larger audience, raise brand awareness, and provide more personalized customer experiences. The hospitality industry must address the importance of providing a consistent customer experience. From check-in to check-out, mobile apps and wearable devices are being used to improve the customer experience. Virtual assistants, for example, are now available in some hotels and can be used to control room features, make restaurant reservations, and access information about the hotel and its surroundings.' />}/>

          <Route path="/Services" element={<Services executeScroll={executeScroll} setVisible={setVisible} />} />
          <Route path="/Services/product" element={<ServiceInd executeScroll={executeScroll} setVisible={setVisible} bg="bg-my-yellow" img="Product Developement Single.png" title='Product Development' detail="Whether you are a business owner who wants to pivot the business into digital form or a budding start-up founder there to challenge the status quo. The need for product development is true. It's needed to create real value from a mere idea. Thus, our expertise to understand your requirements, perform end-to-end product development, and actively manage your maintenance whenever required. We don't just offer product development solution but a partnership in your business journey till eternity."/>}/>
          <Route path="/Services/webDevelopment" element={<ServiceInd  executeScroll={executeScroll} setVisible={setVisible} bg="bg-my-blue" img="web developement Single.png" title='Web Development' detail="Hosting your business online is first step to experience the power of digitization. An active presence in online dimension makes the world come closure to your business, understand the value you offer, and scale to newer heights. We at Red Dot Innovative create web solution that captures the soul of your business offering. This tried and tested method have yielded  our customer to gain tremendous organic reach and virality."/>}/>
          <Route path="/Services/iot" element={<ServiceInd  executeScroll={executeScroll} setVisible={setVisible}bg="bg-Primary-Colour" img="IOT Single.png" title='Internet Of Things' detail="Deployment of the internet of things across industries has gained huge momentum in recent times. Many businesses have already utilized the potential to streamline processes with us. And many are still struggling to harness the true potential of IoT (Internet of Things). IoT enablement for a business requires a deep understanding of the business and the right mix of technical expertise. And Red Dot Innovative qualifies in both requirements. Thus, in the past, we have helped multiple businesses opt for IoT to reduce operations costs and subsequently increase business revenue."/> }/>
          <Route path="/Services/UIdesign" element={<ServiceInd  executeScroll={executeScroll} setVisible={setVisible} bg="bg-my-green" img="ui design consultancy Single.png" title='UI Design Consultancy'  detail='We hold deep expertise in UI architecture to build seamless user flow and design for digital solutions. The UI design consultancy helps businesses not to sacrifice on user experience in the quest to make business digital, rather augment the user experience with the scientific use of UI design and its salient fundamentals.'/>}/>

          <Route path="/Services/DataAnalytics" element={<ServiceInd  executeScroll={executeScroll} setVisible={setVisible}bg="bg-my-blue" img="Data analytics Single.png" title='Data Analytics'  detail='Data is the new oil for this as well as the upcoming generations to come. Many businesses with internal data analytics teams have used to transform their business and offer great products to customers. At Red Dot Innovative, we too have helped numerous business to translate data into deep insights, build innovative products, acquire market share and clock higher revenues.'/>}/>

          <Route path="/Services/professional-services" element={<StaffPage executeScroll={executeScroll} setVisible={setVisible} bg="bg-my-blue" />}/>
          
          <Route path="/contact-us" element={<ContactUs setVisible={setVisible}/>} />
          <Route path="/ourworks" element={<ExploreWorks setVisible={setVisible} />} >
            <Route index element={<EatsEzy state={{
             bg_image : "../explore-eatsezy-bg.png",image: "../explore-eatzezy.png", title: 'EatsEzy', shortDesc: "Mobile App Design & Developement", Description: "EatsEzy is built on a revolutionary idea to transform the food-tech space completely. The founders envisioned making customers digitally book their dining spot for any occasion at any of the EatsEzy chains of restaurants. Moreover, the smart solution developed by Red Dot Inoovative made it extremely simple to record bookings, run a smooth operation, collect advance payments, manage internal staff proportional to predicted footfall, etc. Thus, Eatszy witnessed a steep growth in establishing itself organically by using the solutions that were tailored by Red Dot innovatie as per its requirements.", bg: 'bg-Primary-Colour'
            }} />} />
            <Route path="bigfoot" element={<EatsEzy state={{
             bg_image : "../explore-bigfoot-bg.png",image: "../explore-bigfoot.png", title: 'BigFoot Logistics', shortDesc: "Mobile App Design & Developement", Description: "Biggoot Logistics is a pioneer in the cargo and logistics segment. They wanted to scale their business and eliminate rusty processes in day-to-day business operations. Thus, incorporating digital solutions into business felt like a crucial requirement. And we were chosend as their trusted technology partner to innovate a new cargo freight tracking system, automate all offline processes digitally, and accelerate the growth to scalability.", bg: 'bg-[#D74008]'
            }} />} />
            <Route path="acai" element={<EatsEzy state={{
             bg_image : "../explore-acai-bg.png",image: "../explore-acai.png", title: 'Project Acai', shortDesc: "Mobile App Design & Developement", Description: "Project Acai is a solution that was build to a grievous problem. It was during COVID-19 times to manage customer footfall, allow safe shopping experiences, and collect cashless payments at the food store. Due to all these reasons the food store business revenue plummeted until Red Dot Innovative was hired to build technology solutions to allow customers to their shopping slots, use QR codes to get the same shopping experience without violating of COVID protocols and make payments digitally. With the adoption of technology not only dif project Acai gain traction but also recorded high revenue at a time when many businesses went to bankruptcy.", bg: 'bg-[#592D80]'
            }} />} />
            <Route path="legate" element={<EatsEzy state={{
             bg_image : "../explore-legate-bg.png",image: "../explore-legate.png", title: 'LEGATE', shortDesc: "Mobile App Design & Developement", Description: "Legate used to offer balcony redressal and fitting services to its customers through the offline channel. The quality and value they offered to their customers were great. But soon they realized they cant't offer the same services to the masses. Thus, they pivoted to transform their business into digital with the help of Red Dot Innovative. Now, they offer augmented service to their customer grievance redressal on go from the App. Moreover, to maintain the demand now they also manage their human resource via digital solutions.", bg: 'bg-[#F47325]'
            }} />} />
            <Route path="express-cart" element={<EatsEzy state={{
             bg_image : "null",image: "../explore-express-cart.png", title: 'Express Cart', shortDesc: "Mobile App Design & Developement", Description: "Express Cart is built to solve online bookings of daily goods, easy tracking of the goods, and last-mile delivery of the goods. The uniqueness of Express Cart lies in the adaption of technology and innovation to streamline operations. The resulted in the reduced cost of business operations and increases revenue margins. Thus, the amaigamation of Express Cart's genuine intent to cater the best to its customers and Red Dot Innovative's expertise resulted in making it a great product.", bg: 'bg-[#F7BE47]'
            }} />} />
            <Route path="you-adme" element={<EatsEzy state={{
             bg_image : "../explore-youadme-bg.png",image: "../explore-youadme.png", title: 'YouAdMe', shortDesc: "Mobile App Design & Developement", Description: "You adme is social networking cum marketing platform for influencers. It aims to empower new-age influencers. The use of You adme influencers can manage their existing followers and increase more followers. All this with help them sell the products of their sponsors, The unique advance tech stack built by Red Dot Innovatice made it simple for the followers of and influencer to select the products for delivery and make cashless payments for the same.", bg: 'bg-[#FDCF09]'
            }} />} />
            <Route path="orizon-motorcycles" element={<EatsEzy state={{
             bg_image : "../explore-orizon-motors-bg.png",image: "../explore-orizon-motorcycles.png", title: 'Orizon Motorcycles', shortDesc: "Mobile App Design & Developement", Description: "Orizon motorcycles are known for high-quality new and pre-owned motorcycles, offering a wide range of brands, models, and styles to suit every rider's taste. They are committed to provide their customers with the best possible motorcycle buying experience. RedDotApps collaborated with Orizon to build a one-stop app that offers financing options that are flexible and tailored to meet their customers unique needs. We also offer a wide range of services thru the app to help keep their bike in top condition. With just a click of a button, customers are able to engage a variety of expert technicians to provide maintenance, repairs, and upgrades to ensure that their motorcycle runs smoothly and safely. This allows Orizon’s customers ease in helping them find the perfect motorcycle and providing them with exceptional service and support.", bg: 'bg-[#F2994A]'
            }} />} />
            <Route path="osma" element={<EatsEzy state={{
             bg_image : "../explore-ofms-bg.png",image: "../explore-OFMS.png", title: 'OFMS', shortDesc: "Mobile App Design & Developement", Description: "Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ", bg: 'bg-[#F79635]'
            }} />} />
          </Route>
        </Routes>
    </Router>
  );
}

export default App;
