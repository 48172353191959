import React from "react";
import './ServicesBox.css';
import { Link} from "react-router-dom";

export default function ServicesBox({icon,bg,phone,first,last,text}) {
  return (
   
   
      <div className={` ${!phone && 'hidden'} servicesBox msm:block bg-secondary-2 p-6`} style={{color:'rgba(37, 40, 43, 1)'}}>
          <Link to="/services">
      <h3 className="text-white mb-7 text-center text-xl sm:text-2xl msm:text-3xl md:text-4xl font-extrabold ">
        {first} {last}
      </h3>
      {/* <p className="text-white my-4 w-3/4 " >{text}</p> */}
      <div className={`h-40  md:h-52 w-40  md:w-52 lg:h-80 lg:w-80  mt-8 mb-5 border-[6px] border-white rounded-full ${bg} mx-auto mt-auto`}>
        <img className=" h-full w-full" src={icon} alt=""  />
      </div>

      </Link>
    </div>
 

  );
}
