import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

export default function IndustriesInnerBox({Industries, img, title, subtitle, detail }) {

    useEffect(() => {

    }, [])
    
    return (
        <div ref={Industries} className="snap-start relative text-center bg-center bg-cover bg-no-repeat flex flex-col gap-9 h-screen bg-black">
            <img src={`../../../${img}`} className='h-screen object-cover absolute top-0 left-0 w-screen opacity-30' alt="" />
            <h4 className="px-44 text-Primary-Colour mt-12 tracking-widest font-semibold ">{title}</h4>
            <p className='px-44 text-4xl font-black text-white'>{subtitle}</p>
            <p className='px-20 font-medium text-xl text-white'>{detail}</p>
        </div>
    )
}
