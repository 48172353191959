import React, { useEffect, useState } from 'react'
import ContactUsBox from '../components/ContactUs/ContactUsBox'
import FooterBottom from '../components/Footer/FooterBottom'
import { handleScroll } from '../components/Helper/Helper';
import './ContactUs.css'
import axios from 'axios';

export default function ContactUs({ setVisible }) {

    let contactBody = document.getElementsByClassName("contact_body")[0]
    const [prevScrollpos, setPrevScrollPos] = useState(0);
    const [name , setName] = useState("");
    const [email,setEmail] = useState("");
    const [number,setNumber]=useState("");
    const [Company,setCompany] = useState("");
    const [query,setQuery] = useState("");

    const contactSubmission = ()=>{
        let url = 'https://bigfoot.reddotapps.com.sg/api/contact-form-reddotinnovative';
        let data = {
            full_name: name,
            company_name: Company,
            contact_number: number,
            email: email,
            msg: query
          };
          axios.post(url, data, {}).then(response => {
            if(response.data.success) {
              console.log("Your message has been sent successfully!");
            } else {
              console.log("Something went wrong!");
            }
          })
    }
    

    useEffect(() => {
        if (!contactBody) {
            contactBody = document.getElementsByClassName("contact_body")[0]
        }
        contactBody?.addEventListener('scroll', () => handleScroll(contactBody, prevScrollpos, setVisible, setPrevScrollPos));
        return () => contactBody?.removeEventListener('scroll', () => handleScroll(contactBody, prevScrollpos, setVisible, setPrevScrollPos))
    }, [contactBody?.scrollTop])

    useEffect(() => {
        setVisible(true)
    }, [])

    return (
        <div className="w-full  contact_body h-screen absolute">
            <div className="pt-24 flex flex-col-reverse md:flex-row snap-start relative -z-[2] h-[100vh] px-6 mx-0 bg-secondary-2 md:mx-8 ">
                <div className='z-[2] absolute bottom-40 md:static'>
                    <h1 className="text-2xl pt-16 mvsm:pt-16 vsm:text-3xl sm:text-4xl mvsm:text-5xl md:text-7xl  xl:text-8xl text-white font-extrabold lg:font-black">
                        Let’s Innovate
                    </h1>
                    <h1 className="text-2xl pt-2 mvsm:pt-2 vsm:text-3xl sm:text-4xl mvsm:text-5xl md:text-7xl  xl:text-8xl text-white font-extrabold lg:font-black">
                        something great
                    </h1>
                    <h1 className="text-2xl pt-2 mvsm:pt-2 vsm:text-3xl sm:text-4xl mvsm:text-5xl md:text-7xl  xl:text-8xl text-white font-extrabold lg:font-black">
                        together !
                    </h1>
                </div>
                <div className='absolute contactBulb justify-center md:hidden flex xl:flex top-0 right-1/2 translate-x-1/2 md:translate-x-0 md:right-8'>
                    <img className='h-[50vh] lg:h-[60vh] xl:h-[60vh]' src="Group-39416.svg" alt="" srcSet="" />
                    <img className='h-[40vh] lg:h-[50vh] xl:h-[50vh]' src="Group-39417.svg" alt="" srcSet="" />
                    <img className='h-[45vh] lg:h-[55vh] xl:h-[55vh]' src="Group-39416.svg" alt="" srcSet="" />
                </div>
            </div>
            <div className='snap-start bg-white shadow-[0px_8px_16px_rgba(0,0,0,0.25)] mx-2 md:mx-14 -mt-10 mvsm:-mt-12 lg:-mt-16 px-3 py-4 mvsm:px-8 mvsm:py-14'>
                <h2 className='text-xl sm:text-2xl mvsm:text-3xl md:text-4xl xl:text-5xl  font-extrabold text-secondary-2'>
                    Tell Us About Your Requirements
                </h2>
                <p className='pt-4 text-base sm:text-lg mvsm:text-xl md:text-2xl font-medium mvsm:font-bold text-secondary-2'>Complete the form below or call us on +65 8100 0443 Our team
                    will be there to help you
                </p>
                <form className='flex mt-5 msm:mt-10 flex-col gap-6 mvsm:gap-8'>
                    <div className='flex flex-col max-w-screen-msm gap-2'>
                        <label className='mvsm:font-semibold w-fit' htmlFor="name ">Name</label>
                        <input onChange={(e)=>setName(e.target.value)} className='border border-[#C4C4C4] rounded-sm px-4 py-1' type="text" name="name" id="name" />
                    </div>
                    <div className='flex flex-col max-w-screen-msm gap-2'>
                        <label className='mvsm:font-semibold w-fit' htmlFor="email">Email Address</label>
                        <input onChange={(e)=>setEmail(e.target.value)}className='border border-[#C4C4C4] rounded-sm px-4 py-1' type="email" name="email" id="email" />
                    </div>
                    <div className='flex flex-col max-w-screen-msm gap-2'>
                        <label className='mvsm:font-semibold w-fit' htmlFor="number">Contact Number</label>
                        <input onChange={(e)=>setNumber(e.target.value)} className='border border-[#C4C4C4] rounded-sm px-4 py-1' type="tel" name="number" id="number" />
                    </div>
                    <div className='flex flex-col max-w-screen-msm gap-2'>
                        <label className='mvsm:font-semibold w-fit' htmlFor="cpmpanyName">Company Name</label>
                        <input onChange={(e)=>setCompany(e.target.value)} className='border border-[#C4C4C4] rounded-sm px-4 py-1' type="text" name="cpmpanyName" id="cpmpanyName" />
                    </div>
                    <div className='flex flex-col max-w-screen-msm gap-2'>
                        <label className='mvsm:font-semibold w-fit' htmlFor="howHelp">How Can We Help You?</label>
                        <textarea onChange={(e)=>setQuery(e.target.value)} className='border border-[#C4C4C4] rounded-sm px-4 py-1' name="howHelp" id="howHelp" cols="30" rows="10"></textarea>
                    </div>
                    <button onClick={contactSubmission}className="max-w-min bg-secondary px-14 rounded-sm py-2 text-white font-bold text-base">
                        Submit
                    </button>
                </form>
            </div>
            <div className='snap-start mt-14'>
                <ContactUsBox country='SINGAPORE' address='3, Fraser Street #08-21, Duo Tower, Singapore, 189352' number='+65 6950 2671' email='sales@reddotinnovative.com' image='about-us-pattern.png' bg='bg-[#FFC8D2]' />
            </div>
            <div className='snap-start'>
                <ContactUsBox country='INDIA' address='30 Sahara Parisar, Idgah Hills, Bhopal, MP, 462001' number='' email='sales@reddotinnovative.com' image='india-office.png' bg='bg-[#CBE5FF]' />
            </div>
            <div className='snap-start mb-14'>
                <ContactUsBox country='UAE' address='Sama Towers, Sheikh Zayed Road, Dubai UAE' number='+971 56 182 9015' email='sales@reddotinnovative.com' image='uae-office.png' bg='bg-[#FEE5AB]' />
            </div>
            <div className="snap-start">
                <FooterBottom />
            </div>
        </div>
    )
}
