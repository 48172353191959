import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

export default function ServiceInnerBox({ img,foodRef, title, detail ,bg }) {

    useEffect(() => {

    }, [])
    
    return (
        <div ref={foodRef} className='px-10'>
        <div className='flex gap-7 lg:flex-row sm:flex-col-reverse '>
        <img className={`w-[100vw] rounded-full min-h-full object-cover sm:w-[100vw] lg:w-1/3 ${bg}`} src={`../../../${img}`} alt="" />
        <div className='flex flex-col gap-9  justify-center'>
          <h4 className="text-Primary-Colour text-4xl tracking-widest font-semibold sm:text-center lg:text-left">{title}</h4>
          {/* <p className='text-4xl font-black text-secondary-2 sm:text-center lg:text-left'>{subtitle}</p> */}
          <p className='font-medium text-xl text-secondary-2 sm:text-center lg:text-left'>{detail}</p>
        </div>
      </div>
      </div>
    )
}
