import React, { useEffect, useState } from 'react'
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer'
import FooterBottom from '../Footer/FooterBottom'
import { handleScroll } from '../Helper/Helper';
import ExploreWorksBox from './ExploreWorksBox';

import './ExploreWorks.css';

export default function ExploreWorks({ setVisible }) {

    const navigate = useNavigate()
    const handleMouseEnter = (str) => {
        navigate(`${str}`)
    }

    useEffect(() => {
    
        window.scrollTo(0, 0);
      
     
    }, []);

    let exploreWorksBody = document.getElementsByClassName("explore_works")[0]
    const [prevScrollpos, setPrevScrollPos] = useState(0);

    useEffect(() => {
        if (!exploreWorksBody) {
            exploreWorksBody = document.getElementsByClassName("explore_works")[0]
        }
        exploreWorksBody?.addEventListener('scroll', () => handleScroll(exploreWorksBody, prevScrollpos, setVisible, setPrevScrollPos));
        return () => exploreWorksBody?.removeEventListener('scroll', () => handleScroll(exploreWorksBody, prevScrollpos, setVisible, setPrevScrollPos))
    }, [exploreWorksBody?.scrollTop])

    useEffect(() => {
        setVisible(true)
    }, [])

    return (
        <div className="explore_works  h-screen relative">
            <div className='snap-start pt-24 h-screen hidden lg:flex'>
                <Outlet />
                <div className='flex relative'>
                    <div className=' h-full flex text-xl justify-around gap-9 flex-1 min-w-[20vw] pl-5 lg:pl-8 py-6 font-bold flex-col '>
                        <div className=''></div>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("/ourworks") }}
                            className={({ isActive }) =>
                                isActive ? "text-Primary-Colour relative left-10 EatsEzy" : "text-my-white "
                            }

                            to="/ourworks"
                        // to="/ourworks"
                        >
                            EatsEzy
                        </NavLink>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("bigfoot") }}
                            className={({ isActive }) =>
                                isActive ? "text-[#D74008] relative left-10 bigfoot" : "text-my-white"
                            }
                            to="bigfoot"

                        // to="bigfoot"
                        >
                            BigFoot Logistics
                        </NavLink>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("acai") }}
                            className={({ isActive }) =>
                                isActive ? "text-[#592D80] relative left-10 acai" : "text-my-white"
                            }
                            to="acai"

                        // to="acai"
                        >
                            Project Acai
                        </NavLink>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("legate") }}
                            className={({ isActive }) =>
                                isActive ? "text-[#F47325] relative left-10 legate" : "text-my-white"
                            }
                            to="legate"

                        // to="legate"
                        >
                            LEGATE
                        </NavLink>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("express-cart") }}
                            className={({ isActive }) =>
                                isActive ? "text-[#F7BE47] relative left-10 express-cart" : "text-my-white"
                            }
                            to="express-cart"

                        // to="express-cart"
                        >
                            Express Cart
                        </NavLink>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("you-adme") }}
                            className={({ isActive }) =>
                                isActive ? "text-[#FDCF09] relative left-10 you-adme" : "text-my-white"
                            }
                            to="you-adme"

                        // to="you-adme "
                        >
                            YouAdMe
                        </NavLink>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("orizon-motorcycles") }}
                            className={({ isActive }) =>
                                isActive ? "text-[#F2994A] relative left-10 orizon-motorcycles" : "text-my-white"
                            }
                            to="orizon-motorcycles"

                        // to="orizon-motorcycles "
                        >
                            Orizon Motorcycles
                        </NavLink>
                        <NavLink
                            end
                            onMouseEnter={() => { handleMouseEnter("osma") }}
                            className={({ isActive }) =>
                                isActive ? "text-[#F79635] relative left-10 osma" : "text-my-white"
                            }
                            to="osma"

                        // to="osma"
                        >
                            OFMS
                        </NavLink>
                    </div>
                    <h2 style={{ writingMode: "vertical-lr" }} className='absolute top-1/2 -translate-y-1/2 right-0 font-bold text-explore/work/vr pr-5 w-fit text-7xl lg:text-8xl'>Work</h2>
                </div>
            </div>
            {/* <div className='snap-start pt-24 flex flex-col gap-5 md:hidden'> */}
            <div className='lg:hidden snap-start pt-24'><ExploreWorksBox bg_image="../explore-eatsezy-bg.png" bg_olour='bg-Primary-Colour' title="EatsEzy" description="EatsEzy is built on a revolutionary idea to transform the food-tech space completely. The founders envisioned making customers digitally book their dining spot for any occasion at any of the EatsEzy chains of restaurants. Moreover, the smart solution developed by Red Dot Inoovative made it extremely simple to record bookings, run a smooth operation, collect advance payments, manage internal staff proportional to predicted footfall, etc. Thus, Eatszy witnessed a steep growth in establishing itself organically by using the solutions that were tailored by Red Dot innovatie as per its requirements." subtitle="Mobile App Design & Developement" image="../explore-eatzezy.png" /></div>
            <div className='lg:hidden snap-start mt-5'><ExploreWorksBox bg_image="../explore-bigfoot-bg.png" bg_olour='bg-[#D74008]' title="BigFoot Logistics" description="Biggoot Logistics is a pioneer in the cargo and logistics segment. They wanted to scale their business and eliminate rusty processes in day-to-day business operations. Thus, incorporating digital solutions into business felt like a crucial requirement. And we were chosend as their trusted technology partner to innovate a new cargo freight tracking system, automate all offline processes digitally, and accelerate the growth to scalability." subtitle="Mobile App Design & Developement" image="../explore-bigfoot.png" /></div>
            <div className='lg:hidden snap-start mt-5'><ExploreWorksBox bg_image="../explore-acai-bg.png" bg_olour='bg-[#592D80]' title="Project Acai" description="Project Acai is a solution that was build to a grievous problem. It was during COVID-19 times to manage customer footfall, allow safe shopping experiences, and collect cashless payments at the food store. Due to all these reasons the food store business revenue plummeted until Red Dot Innovative was hired to build technology solutions to allow customers to their shopping slots, use QR codes to get the same shopping experience without violating of COVID protocols and make payments digitally. With the adoption of technology not only dif project Acai gain traction but also recorded high revenue at a time when many businesses went to bankruptcy." subtitle="Mobile App Design & Developement" image="../explore-acai.png" /></div>
            <div className='lg:hidden snap-start mt-5'><ExploreWorksBox bg_image="../explore-legate-bg.png" bg_olour='bg-[#F47325]' title="LEGATE" description="Legate used to offer balcony redressal and fitting services to its customers through the offline channel. The quality and value they offered to their customers were great. But soon they realized they cant't offer the same services to the masses. Thus, they pivoted to transform their business into digital with the help of Red Dot Innovative. Now, they offer augmented service to their customer grievance redressal on go from the App. Moreover, to maintain the demand now they also manage their human resource via digital solutions." subtitle="Mobile App Design & Developement" image="../explore-legate.png" /></div>
            <div className='lg:hidden snap-start mt-5'><ExploreWorksBox bg_image="null" bg_olour='bg-[#F7BE47]' title="Express Cart" description="Express Cart is built to solve online bookings of daily goods, easy tracking of the goods, and last-mile delivery of the goods. The uniqueness of Express Cart lies in the adaption of technology and innovation to streamline operations. The resulted in the reduced cost of business operations and increases revenue margins. Thus, the amaigamation of Express Cart's genuine intent to cater the best to its customers and Red Dot Innovative's expertise resulted in making it a great product." subtitle="Mobile App Design & Developement" image="../explore-express-cart.png" /></div>
            <div className='lg:hidden snap-start mt-5'><ExploreWorksBox bg_image="../explore-youadme-bg.png" bg_olour='bg-[#FDCF09]' title="YouAdme" description="You adme is social networking cum marketing platform for influencers. It aims to empower new-age influencers. The use of You adme influencers can manage their existing followers and increase more followers. All this with help them sell the products of their sponsors, The unique advance tech stack built by Red Dot Innovatice made it simple for the followers of and influencer to select the products for delivery and make cashless payments for the same." subtitle="Mobile App Design & Developement" image="../explore-youadme.png" /></div>
            <div className='lg:hidden snap-start mt-5'><ExploreWorksBox bg_image="../explore-orizon-motors-bg.png" bg_olour='bg-[#F2994A]' title="Orizon Motorcycles" description="Orizon motorcycles are known for high-quality new and pre-owned motorcycles, offering a wide range of brands, models, and styles to suit every rider's taste. They are committed to provide their customers with the best possible motorcycle buying experience. RedDotApps collaborated with Orizon to build a one-stop app that offers financing options that are flexible and tailored to meet their customers unique needs. We also offer a wide range of services thru the app to help keep their bike in top condition. With just a click of a button, customers are able to engage a variety of expert technicians to provide maintenance, repairs, and upgrades to ensure that their motorcycle runs smoothly and safely. This allows Orizon’s customers ease in helping them find the perfect motorcycle and providing them with exceptional service and support." subtitle="Mobile App Design & Developement" image="../explore-orizon-motorcycles.png" /></div>
            <div className='lg:hidden snap-start my-5'><ExploreWorksBox bg_image="../explore-ofms-bg.png" bg_olour='bg-[#F79635]' title="OFMS" description="Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy " subtitle="Mobile App Design & Developement" image="../explore-OFMS.png" /></div>
            {/* </div> */}
            <div className="snap-start">
                <Footer />
            </div>
            <div className="snap-start">
                <FooterBottom />
            </div>
        </div>
    )
}
