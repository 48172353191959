import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const menuCloseIcon = (
  <svg
    className="fill-Primary-Colour"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
  >
    <path d="M3.25 17.625v-1.5h17.5v1.5Zm0-4.875v-1.5h17.5v1.5Zm0-4.875v-1.5h17.5v1.5Z" />
  </svg>
);
const menuOpenIcon = (
  <svg
    className="fill-Primary-Colour"
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    width="24"
  >
    <path d="M6.4 19 5 17.6l5.6-5.6L5 6.4 6.4 5l5.6 5.6L17.6 5 19 6.4 13.4 12l5.6 5.6-1.4 1.4-5.6-5.6Z" />
  </svg>
);
export default function Navbar({
  visible,
  homeRef,
  aboutRef,
  industriesRef,
  servicesRef,
  executeScroll,
}) {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [color, setColor] = useState("");
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [ourWorks, setourWorks] = useState(false);

  const handleMenu = () => {
    // console.log(!isMenuOpen)
    setIsMenuOpen(!isMenuOpen);
    // console.log(isMenuOpen)
  };
  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setHome(true);
        setAbout(false);
        setourWorks(false);
        return setColor("bg-white");
      case "/ourworks":
        setHome(false);
        setourWorks(true);
        return setColor("bg-Primary-Colour");
      case "/ourworks/bigfoot":
        return setColor("bg-[#D74008]");
      case "/ourworks/acai":
        return setColor("bg-[#592D80]");
      case "/ourworks/legate":
        return setColor("bg-[#F47325]");
      case "/ourworks/express-cart":
        return setColor("bg-[#F7BE47]");
      case "/ourworks/you-adme":
        return setColor("bg-[#FDCF09]");
      case "/ourworks/orizon-motorcycles":
        return setColor("bg-[#F2994A]");
      case "/ourworks/osma":
        return setColor("bg-[#F79635]");
      case "/about-us":
        setHome(false);
        setourWorks(false);
        return setColor("bg-white");
        return setAbout(true);
      case "/contact-us":
        setHome(false);
        setourWorks(false);
        return setColor("bg-white");
        return setAbout(false);
      case "/industries":
        setourWorks(false);
        setHome(false);
        return setColor("bg-white");
      case "/Services":
        setourWorks(false);
        setHome(false);
        return setColor("bg-white");
      default:
        return setColor("bg-white");
    }
  }, [location.pathname]);

  // console.log(location.pathname)

  return (
    <div
      className={`z-50 h-24 top-0 left-0 fixed ${color} transition-all duration-150 ${
        visible ? "top-0" : "-top-24"
      } items-center w-full flex justify-between py-4 px-4 mvsm:px-6 md:px-20 msm:px-10`}
    >
      <Link to="/">
        <img className="h-16 w-auto" src="../../RedDot Logo.png" alt="logo" />
      </Link>
      <div onClick={handleMenu} className="block smd:hidden">
        {isMenuOpen ? menuOpenIcon : menuCloseIcon}
      </div>
      <div
        className={`px-4 ${
          isMenuOpen ? "h-[90vh] overflow-y-auto py-8" : "overflow-hidden h-0"
        } flex box-border flex-col gap-10  duration-200 transition-all ease-in-out   left-0 mvsm:px-6 smd:hidden top-24 absolute w-full bg-white`}
      >
        <div className="flex flex-col gap-6">
          <Link onClick={() => setIsMenuOpen(false)} to="/Services">
            <h2 className="text-Primary-Colour text-xl font-bold">Services</h2>
            <p className="text-my-white font-semibold">What we Offers</p>
          </Link>
          <Link onClick={() => setIsMenuOpen(false)} to="/ourworks">
            <h2 className="text-Primary-Colour text-xl font-bold">Works</h2>
            <p className="text-my-white font-semibold">Our Creations</p>
          </Link>
          <Link onClick={() => setIsMenuOpen(false)} to="/about-us">
            <h2 className="text-Primary-Colour text-xl font-bold">About Us</h2>
            <p className="text-my-white font-semibold">Get to know us</p>
          </Link>
          <Link onClick={() => setIsMenuOpen(false)} to="/contact-us">
            <h2 className="text-Primary-Colour text-xl font-bold">Contacts</h2>
            <p className="text-my-white font-semibold">Find Us</p>
          </Link>
        </div>
        <div>
          <h2 className="text-black text-2xl font-bold leading-8">
            WE CREATE DIGITAL EXPERIENCES
          </h2>
          <p className="text-my-white my-3 font-semibold">
            Acclerate.Automate.Innovate
          </p>
          <p className="text-black">
            Red Dot Innovative helps you to take your business to the higher
            orbit with science of digitization and cutting-edge technology
          </p>
        </div>
        <div className="flex flex-col gap-6">
          <div>
            <p className="text-my-white text-xl font-semibold">Call Us</p>
            <p className="text-black mt-1 font-bold">+65 69502671</p>
          </div>
          <div>
            <p className="text-my-white text-xl font-semibold">Write Us</p>
            <p className="text-black mt-1 font-bold">
              Sales@reddotinnovative.com
            </p>
          </div>
        </div>
        <Link onClick={() => setIsMenuOpen(false)} to="/contact-us">
          <button className=" mx-auto bg-secondary px-5 rounded-sm py-2 text-white font-bold text-base">
            GET FREE QUOTE
          </button>
        </Link>
      </div>
      <div className="hidden smd:flex items-center gap-6 md:gap-12 justify-between">
        <nav className="flex text-text/heading gap-4 md:gap-7 font-bold text-base w-full justify-between">
          <Link
            className={`${
              ourWorks ? "text-decoration" : "hover:text-Primary-Colour"
            } ${home ? "text-Primary-Colour" : ""} ${
              ourWorks ? "text-white" : ""
            }`}
            onClick={() => {
              executeScroll(homeRef);
            }}
            to="/"
          >
            Home
          </Link>
          <Link
            className={`${
              location.pathname === "/Services" ||
              location.pathname === "/Services/product" ||
              location.pathname === "/Services/webDevelopment" ||
              location.pathname === "/Services/iot" ||
              location.pathname === "/Services/UIdesign" ||
              location.pathname === "/Services/Enterprise" ||
              location.pathname === "/Services/DataAnalytics"
                ? "text-Primary-Colour"
                : ""
            } ${ourWorks ? "text-decoration" : "hover:text-Primary-Colour"} ${
              ourWorks ? "text-white" : ""
            }`}
            onClick={() => {
              executeScroll(servicesRef);
            }}
            to="/Services"
          >
            Services
          </Link>
          {/* <Link
            className={`${location.pathname === '/industries'|| location.pathname ==='/industries/logisticandsupplychain'||location.pathname ==='/industries/foodandbeverages'||location.pathname ==='/industries/healthcare'||location.pathname ==='/industries/retail'||location.pathname ==='/industries/constructionAndRealEstate'||location.pathname ==='/industries/hospitality'  ?'text-Primary-Colour' : ''} ${ourWorks?"text-decoration":"hover:text-Primary-Colour"} ${ourWorks?"text-white":""}`}
            onClick={() => {
                executeScroll(industriesRef);
              }}
            to="/industries"
          >
            Industries
          </Link> */}
          <Link
            className={`${
              location.pathname === "/ourworks" ? "text-Primary-Colour" : ""
            } ${ourWorks ? "text-decoration" : "hover:text-Primary-Colour"} ${
              ourWorks ? "text-slate-800" : ""
            }`}
            onClick={() => {
              executeScroll(servicesRef);
            }}
            to="/ourworks"
          >
            Our Works
          </Link>
          <Link
            className={`${
              location.pathname === "/about-us" ? "text-Primary-Colour" : ""
            } ${ourWorks ? "text-decoration" : "hover:text-Primary-Colour"} ${
              about ? "text-Primary-Colour" : ""
            } ${ourWorks ? "text-white" : ""}`}
            onClick={() => {
              executeScroll(aboutRef);
            }}
            to={"/about-us"}
          >
            About Us
          </Link>
        </nav>
        <Link to="/contact-us">
          <button
            className={`min-w-fit transition-colors duration-150 ${
              ourWorks ? "text-decoration" : "hover:bg-Primary-Colour"
            } bg-secondary px-5 rounded-sm py-2 text-white font-bold whitespace-nowrap text-base`}
          >
            GET FREE QUOTE
          </button>
        </Link>
      </div>
    </div>
  );
}
