import React, { useEffect, useState } from "react";
import Footer from "../components/Footer/Footer";
import FooterBottom from "../components/Footer/FooterBottom";
import ServicesBox from "../components/Services/ServicesBox";
import { Swiper, SwiperSlide } from "swiper/react";
import ReactTypingEffect from "react-typing-effect";
import { NavLink } from "react-router-dom";
// import {dedictedTeamIcon} from '../../public/dedicatedTeam.png';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./styles.css";
import "./Home.css";

import { Autoplay, Pagination, Navigation } from "swiper";
import TestimonialBox from "../components/Testimonial/TestimonialBox";
import { Link } from "react-router-dom";
import { handleScroll } from "../components/Helper/Helper";


export default function Home({
  setVisible,
  executeScroll,
  homeRef,
  ourWorksRef,
  aboutRef,
  industriesRef,
  servicesRef,
}) {
  let homeBody = document.getElementsByClassName("home_body")[0];
  const [prevScrollpos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    if (!homeBody) {
      homeBody = document.getElementsByClassName("home_body")[0];
    }
    homeBody?.addEventListener("scroll", () =>
      handleScroll(homeBody, prevScrollpos, setVisible, setPrevScrollPos)
    );
    return () =>
      homeBody?.removeEventListener("scroll", () =>
        handleScroll(homeBody, prevScrollpos, setVisible, setPrevScrollPos)
      );
  }, [homeBody?.scrollTop]);

  useEffect(() => {
    setVisible(true);
  }, []);

  const [isHoveringonFood, setIsHoveringonFood] = useState(false);
  const [isHoveringonRetail, setIsHoveringonRetail] = useState(false);
  const [isHoveringonHealth, setIsHoveringonHealth] = useState(false);
  const [isHoveringonLogistics, setIsHoveringonLogistics] = useState(false);
  const [isHoveringonBank, setIsHoveringonBank] = useState(false);
  const [isEcommerce, setIsEcommerce] = useState(false);

  const handleMouseOverBank = () => {
    setIsHoveringonBank(true);
  };
  const handleMouseOutBank = () => {
    setIsHoveringonBank(false);
  };
  const handleMouseOverEcommerce = () => {
    setIsEcommerce(true);
  };
  const handleMouseOutEcommerce = () => {
    setIsEcommerce(false);
  };

  const handleMouseOverFood = () => {
    setIsHoveringonFood(true);
  };

  const handleMouseOutFood = () => {
    setIsHoveringonFood(false);
  };

  const handleMouseOverHealth = () => {
    setIsHoveringonHealth(true);
  };

  const handleMouseOutHealth = () => {
    setIsHoveringonHealth(false);
  };

  const handleMouseOverRetail = () => {
    setIsHoveringonRetail(true);
  };

  const handleMouseOutRetail = () => {
    setIsHoveringonRetail(false);
  };

  const handleMouseOverLogistics = () => {
    setIsHoveringonLogistics(true);
  };

  const handleMouseOutLogistics = () => {
    setIsHoveringonLogistics(false);
  };

  return (
    <div className="relative home_body h-screen ">
      <div
        ref={homeRef}
        className="h-screen pt-24 pb-20 snap-start  px-4 mvsm:px-6 md:px-20 msm:px-10"
      >
        <div className="flex">
          <div className="relative w-full">
            <h1 className="text-2xl min-w-fit font-[900] vsm:text-3xl sm:text-4xl mvsm:text-5xl md:text-7xl  xl:text-8xl text-secondary-2 heading">
              Simplifying business with <br />{" "}
              <span className="text-Primary-Colour">
                <ReactTypingEffect
                  text={["Innovation", "Automation", "Acceleration"]}
                  speed={200}
                  eraseSpeed={200}
                  eraseDelay={2000}
                  typingDelay={2000}
                  cursor="_"
                />
              </span>{" "}
              <br /> {/* Your   Business */}
            </h1>
            <p className="mt-7 font-[400] header_para">
              Red Dot Innovative helps you to take your business to the higher
              orbit with science of digitization and cutting-edge technology
            </p>
            <button
              onClick={() => {
                executeScroll(ourWorksRef);
              }}
              className=" mt-7 min-w-fit bg-secondary px-14 rounded-sm py-2 text-white font-bold text-base"
            >
              Experience Now
            </button>
            <div className=" absolute top-36 justify-center items-center right-0 hidden lg:flex w-[35%] ">
              <img src="bg circles.svg" alt="" />
              <img
                className="absolute flex self-center"
                src="mobile-mockup.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          onClick={() => {
            executeScroll(aboutRef);
          }}
          className=" border-2 border-secondary-2 rounded-full h-12 w-7 cursor-pointer left-1/2 -translate-x-1/2 absolute bottom-10 lg:mt-0"
        >
          <div className="border bg-secondary-2 border-secondary-2 h-2 rounded-full mx-auto w-fit mt-2 animate-curser_upDown"></div>
        </div>
      </div>
      <div
        ref={aboutRef}
        className=" snap-start px-4 mvsm:px-6 md:px-20 msm:px-10 bg-secondary-2  flex flex-col items-center py-16 gap-5"
      >
        <h4 className="text-Primary-Colour tracking-widest font-semibold ">
          ABOUT US
        </h4>
        <h2 className="text-white text-center text-2xl msm:text-3xl md:text-4xl lg:text-6xl font-extrabold aboutUs_heading">
          {/* <ReactTypingEffect
            text={["Innovate", "Automate", "Acclerate"]}
            speed={100}
        eraseSpeed={100}
        eraseDelay={2000}
        typingDelay={2000}
        cursor="_"
          /> */}
          Great Business with Right Technology Partner
        </h2>
        <p className="text-white mvsm:font-medium font-[500] text-center about_us_para">
          Red Dot Innovative is a trusted technology partner with the expertise
          to provide customized solutions to businesses. The uniquely crafted
          solutions helps the business to serve the best to their valued
          customers, be ahead of their competitors, and grow their brand equity.
          So far we have served more than 20+ businesses and startup across SEA
          (South East Asia) in their digital transformation journey and counting
          more.{" "}
        </p>
        <Link
          to="/about-us"
          className="min-w-fit bg-secondary px-5 rounded-sm py-2 text-white font-bold text-base"
        >
          Discover
        </Link>
      </div>
      <div
        ref={servicesRef}
        className="snap-start px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white flex flex-col items-center py-16 gap-5"
      >
        <h4 className="text-Primary-Colour tracking-widest font-semibold ">
          SERVICES
        </h4>
        <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-black main_heading">
          We Provide Best Services For Business
        </h2>
        <div className="grid py-3 msm:py-16 gap-12 w-11/12 grid-cols-1 msm:grid-cols-2">
          <ServicesBox
            first={"Product"}
            last={"Development"}
            icon="Product Devlopement.png"
            text="Whether you are a business owner who wants to 
pivot the business into digital form or a budding 
start-up founder there to challenge the 
status quo. The need for product development
is true. It's needed to create real value from
a mere idea. 
Thus, our expertise to understand your 
requirements, perform end-to-end product 
development, and actively manage your
maintenance whenever required. We don't just
offer product development solution but a 
partnership in your business journey till eternity."
            bg="bg-my-yellow"
            phone="show"
          />
          <ServicesBox
            first={"Web"}
            last={"Development"}
            icon="Web Developement.png"
            bg="bg-my-blue"
            phone="show"
            text="Hosting your business online is first step to 
experience the power of digitization. An active
presence in online dimension makes the world
 come closure to your business, understand the
value you offer, and scale to newer heights.
We at Red Dot Innovative create web solution
that captures the soul of your business offering.
This tried and tested method have yielded  our
customer to gain tremendous organic reach and
virality."
          />
          <ServicesBox
            first={"Internet of"}
            last={"Things"}
            icon="iot.png"
            bg="bg-Primary-Colour"
            text="Deployment of the internet of things across
industries has gained huge momentum in
recent times. Many businesses have already
utilized the potential to streamline processes
with us. And many are still struggling to harness
the true potential of IoT (Internet of Things).
IoT enablement for a business requires a deep
understanding of the business and the right mix
of technical expertise. And Red Dot Innovative
qualifies in both requirements. Thus, in the
past, we have helped multiple businesses opt
for IoT to reduce operations costs and
subsequently increase business revenue."
          />
          <ServicesBox
            first={"UI Design"}
            last={"Consultancy"}
            icon="SMS.png"
            bg="bg-my-green"
            text="We hold deep expertise in UI architecture to
build seamless user flow and design for digital
solutions. The UI design consultancy helps
businesses not to sacrifice on user experience
in the quest to make business digital, rather
augment the user experience with the scientific
use of UI design and its salient fundamentals."
          />
          <ServicesBox
            first={"Data"}
            last={"Analytics"}
            icon="Data Analytics.png"
            bg="bg-my-blue"
            text="Data is the new oil for this as well as the
upcoming generations to come. Many businesses
with internal data analytics teams have used to
transform their business and offer great
products to customers. 
At Red Dot Innovative, we too have helped
numerous business to translate data into deep
insights, build innovative products, acquire
market share and clock higher revenues."
          />
          <ServicesBox
            first={"Staff"}
            last={"Augmentation"}
            icon="staff augm.png"
            bg="bg-my-green"
            text="In this insanely growing digital world. The new-
age business discover methods and processes
to make enterprise mobility as an integral part
of their daily business.
Enterprise mobility comes with a lot of
advantage. But the best of all is the cost
diversification and pay as you scale. We have 
flawless track record to help business adopt
for enterprise mobility. This service in our
portfolio have changed the business and
increased their operating efficiency with
lowering down the expenses."
          />
        </div>
      </div>

      <div
        ref={industriesRef}
        className="snap-start bg-secondary-2 h-fit relative py-16 "
      >
        <div className="px-4 mvsm:px-6 md:px-20 msm:px-10 flex flex-col items-center gap-5">
          <h4 className="text-Primary-Colour tracking-widest font-semibold ">
            INDUSTRIES
          </h4>
          <h2 className="text-white text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl md:leading-tight font-extrabold main_heading">
            Let Innovation be Your Growth Engine
          </h2>
          <h2 className="text-white text-center">
            We effortlessly tame innovation to make it an integral part of your
            business and help you build new experience across sectors
          </h2>
          <div className="hidden mvsm:flex mt-8 w-full  max-h-[500px] justify-center gap-2">
            <div className="flex max-h-[492px] box-border flex-1 gap-2 flex-col">
              <div
                className="relative h-1/2 "
                onMouseOver={handleMouseOverFood}
                onMouseOut={handleMouseOutFood}
              >
                <img
                  className={`h-full opacity-90 w-full object-cover ${
                    isHoveringonFood ? "opacity-50" : ""
                  } `}
                  src="food-&-beverages.jpeg"
                  alt=""
                />
                {isHoveringonFood && (
                  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                    <img className="mx-auto" src="foodicon.svg" alt="" />
                    <p className="text-lg font-bold text-white">
                      Food & Beverages
                    </p>
                  </div>
                )}
              </div>
              <div
                className="relative h-1/2 "
                onMouseOver={handleMouseOverEcommerce}
                onMouseOut={handleMouseOutEcommerce}
              >
                <img
                  className={`h-full opacity-90 w-full object-cover ${
                    isEcommerce ? "opacity-50" : ""
                  } `}
                  src="Construction & Real Estate.jpg"
                  alt=""
                />
                {isEcommerce && (
                  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                    <img
                      className="mx-auto"
                      style={{ width: "64px" }}
                      src="real estate (1).svg"
                      alt=""
                    />
                    <p className="text-lg text-center font-bold text-white">
                      Construction and Real-estate{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex max-h-[492px] box-border flex-1 gap-2 flex-col">
              <div
                className="h-1/2 relative"
                onMouseOver={handleMouseOverHealth}
                onMouseOut={handleMouseOutHealth}
              >
                <img
                  className={`h-full opacity-90 w-full object-cover ${
                    isHoveringonHealth ? "opacity-50" : ""
                  } `}
                  src="healthcare.jpeg"
                  alt=""
                />
                {isHoveringonHealth && (
                  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                    <img className="mx-auto" src="health-1.svg" alt="" />
                    <p className="text-lg font-bold text-white">Healthcare</p>
                  </div>
                )}
              </div>
              <div
                className="h-1/2 relative"
                onMouseOver={handleMouseOverLogistics}
                onMouseOut={handleMouseOutLogistics}
              >
                <img
                  className={`h-full opacity-90 w-full object-cover ${
                    isHoveringonLogistics ? "opacity-50" : ""
                  } `}
                  src="logistics.jpeg"
                  alt=""
                />
                {isHoveringonLogistics && (
                  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                    <img className="mx-auto" src="logistic-icon.svg" alt="" />
                    <p className="text-lg font-bold text-white">Logistic</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex max-h-[492px] box-border flex-1 gap-2 flex-col">
              <div
                className="relative h-1/2"
                onMouseOver={handleMouseOverRetail}
                onMouseOut={handleMouseOutRetail}
              >
                <img
                  className={`h-full opacity-90 w-full object-cover ${
                    isHoveringonRetail ? "opacity-50" : ""
                  } `}
                  src="Retail.jpg"
                  alt=""
                />
                {isHoveringonRetail && (
                  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                    <img
                      className="mx-auto"
                      style={{ width: "64px" }}
                      src="retail.svg"
                      alt=""
                    />
                    <p className="text-lg text-center font-bold text-white">
                      Retail
                    </p>
                  </div>
                )}
              </div>
              <div
                className="relative h-1/2"
                onMouseOver={handleMouseOverBank}
                onMouseOut={handleMouseOutBank}
              >
                <img
                  className={`h-full opacity-90 w-full object-cover ${
                    isHoveringonBank ? "opacity-50" : ""
                  } `}
                  src="hospitality.jpg"
                  alt=""
                />
                {isHoveringonBank && (
                  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                    <img
                      className="mx-auto"
                      style={{ width: "64px" }}
                      src="hospitality.svg"
                      alt=""
                    />
                    <p className="text-lg font-bold text-white">Hospitality</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <NavLink to='/industries' className="min-w-fit bg-secondary z-10 mt-8 px-5 rounded-sm py-2 text-white font-bold text-base">
          Learn More
        </NavLink> */}
        </div>
        <div className="max-w-[100vw]  mt-8 mvsm:hidden">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper "
          >
            <SwiperSlide>
              <div className="w-full relative h-100px">
                <img
                  className="h-56 hover:opacity-50 opacity-100 w-full object-cover"
                  src="food-&-beverages.jpeg"
                  alt=""
                />
                <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                  <img className="mx-auto " src="foodicon.svg" alt="" />
                  <p className="text-lg font-bold text-white">
                    Food & Beverages
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full relative h-100px">
                <img
                  className="h-56 hover:opacity-50 opacity-100 w-full object-cover"
                  src="healthcare.jpeg"
                  alt=""
                />
                <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                  <img className="mx-auto " src="health-1.svg" alt="" />
                  <p className="text-lg font-bold text-white">Healthcare</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full relative h-100px">
                <img
                  className="h-56 hover:opacity-50 opacity-100 w-full object-cover"
                  src="logistics.jpeg"
                  alt=""
                />
                <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                  <img className="mx-auto " src="logistic-icon.svg" alt="" />
                  <p className="text-lg font-bold text-white">Logistic</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="w-full relative h-100px">
                <img
                  className="h-56 hover:opacity-50 opacity-100 w-full object-cover"
                  src="agri.jpeg"
                  alt=""
                />
                <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]">
                  <img className="mx-auto " src="farming-1.svg" alt="" />
                  <p className="text-lg font-bold text-white">Retailre</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div
        ref={ourWorksRef}
        className="snap-start relative bg-black h-200 py-8 px-4 w-full bg-white  pb-16  mvsm:px-6 md:px-20 msm:px-10 flex flex-col items-center gap-5"
      >
        <img
          className="absolute top-0 left-0 h-full opacity-40 object-cover"
          src="our-work.png"
          alt=""
        />
        <h4 className="text-Primary-Colour relative tracking-widest font-semibold ">
          OUR WORK
        </h4>
        <h2 className="text-white relative text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl md:leading-tight font-extrabold main_heading">
          Write Your Next Chapter with Us
        </h2>
        <p className="text-white mvsm:font-medium text-center work_para">
          Write examplary story starts with trust and we ensure you write a one
          that is an aspiration to rest{" "}
        </p>

        <div className="mt-5 w-full py-8 px-4">
          <Swiper
            // slidesPerView={window.innerWidth < 430 ? 1 : 1.25}
            slidesPerView={1}
            spaceBetween={20}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper "
          >
            <SwiperSlide>
              <div className="relative">
                <img className="" src="EATSEZY.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium">
                    EatsEzy is singapore’s restaurant{" "}
                    <span className=" font-bold text-sm  mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-Primary-Colour ">
                      table booking app
                    </span>
                  </p>
                  <img
                    className="mt-3 mr-3 self-start h-1/12 w-1/12"
                    src="eatsezy-logo.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="ofms.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium">
                    OFMS is{" "}
                    <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#F79635] ">
                      {" "}
                      a Luxury Real Estate and Facility Management.
                    </span>
                  </p>
                  <img
                    className="mt-3 mr-3 self-start h-1/12 w-1/12"
                    src="ofms-logo.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="acai.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium">
                    Project Acai is Singapore’s First{" "}
                    <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#592D80] ">
                      Acai Superfood Cafe
                    </span>
                  </p>
                  <img
                    className="mt-3 mr-3 self-start h-1/12 w-1/12"
                    src="RedDot Logo.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="bigfoot.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium">
                    BigFoot is singapore’s largest{" "}
                    <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#155B9F] ">
                      {" "}
                      logistics & supply chain Group
                    </span>
                  </p>
                  <img
                    className="mt-3 mr-3 self-start h-1/12 w-1/12"
                    src="bigfoot-logo.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="youadme.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium">
                    YouAdMe is a{" "}
                    <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#FDCF09] ">
                      Social Commerce Platform For Businesses
                    </span>
                  </p>
                  <img
                    className="mt-3 mr-3 self-start h-1/12 w-1/12"
                    src="youadme-logo.png"
                    alt=""
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        {/* carousel here to paste      */}

        <NavLink
          to="/ourworks"
          className="min-w-fit bg-secondary z-10 mt-8 px-5 rounded-sm py-2 text-white font-bold text-base"
        >
          View True Stories
        </NavLink>
      </div>
      {/* <div style={{width:"100%"}} className="snap-start py-8 px-4 w-[100vw] bg-white  pb-16  mvsm:px-6 md:px-20 msm:px-10 flex flex-col items-center gap-5">
        <h4 className="text-Primary-Colour tracking-widest font-semibold ">
          OUR SUCCESS
        </h4>
        <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl md:leading-tight font-extrabold main_heading">
        We Support to Ensure Your Success

        </h2>
 
        <p class= " text-secondary-2 text-center mb-6 text-lg font-normal  lg:text-xl sm:px-16 xl:px-48 ">Our pride is in our customer success</p>

        <div className="mt-5 w-full">
          <Swiper
            slidesPerView={window.innerWidth < 430 ? 1 : 1.25}
            spaceBetween={20}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper "
          >
            <SwiperSlide>
              <div className="relative">
                <img className="" src="EATSEZY.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium" >Eatsezy is singapore’s restaurant  <span className=" font-bold text-sm  mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-Primary-Colour ">table booking app</span></p>
                  <img className="mt-3 mr-3 self-start h-1/12 w-1/12" src="eatsezy-logo.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="ofms.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium" >OFMS is <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#F79635] "> a Luxury Real Estate and Facility Management.</span></p>
                  <img className="mt-3 mr-3 self-start h-1/12 w-1/12" src="ofms-logo.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="acai.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium" >Project Acai is Singapore’s First  <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#592D80] ">Acai Superfood Cafe</span></p>
                  <img className="mt-3 mr-3 self-start h-1/12 w-1/12" src="big-foot-logo.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="bigfoot.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium" >Bigfoot is singapore’s largest <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#155B9F] "> logistics & supply chain Group</span></p>
                  <img className="mt-3 mr-3 self-start h-1/12 w-1/12" src="bigfoot-logo.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="relative">
                <img className="" src="youadme.png" alt="" />
                <div className="absolute top-0 h-[41%] justify-between flex   left-1/4">
                  <p className=" text-[#232635CC] text-xl our_success_p self-end lg:text-3xl xl:text-[40px] xl:leading-tight font-medium" >YouAdMe is a <span className=" font-bold text-sm mvsm:text-2xl our_scuucess_span lg:text-4xl xl:text-5xl text-[#FDCF09] ">Social Commerce Platform For Businesses</span></p>
                  <img className="mt-3 mr-3 self-start h-1/12 w-1/12" src="youadme-logo.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div> */}

      {/* <div style={{width:"100%"}} className="snap-start px-4 py-16 bg-[#F4F6F5] w-[100vw]  mvsm:px-6 md:px-20 msm:px-10 flex flex-col items-center gap-5">
        <h4 className="text-Primary-Colour tracking-widest font-semibold ">
          CUSTOMER TESTIMONIAL
        </h4>
        <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl md:leading-tight font-extrabold customer_heading">
        We Thrive on Trust

        </h2>

        <p class= " text-secondary-2 text-center mb-6 text-lg font-normal  lg:text-xl sm:px-16 xl:px-48 ">Discover the feedback from our esteemed customers and learn about their experience with us</p>
        <div className="mt-5  w-full">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <TestimonialBox image='Rectangle 4503.png' description='We are happy to have chosen Red Dot Innovative to
digitize our store operations. The solutions they suggested
along with it time bound implementation helped us to
provide great experience to our customers. They are truly
the best technology partner out there.' name='JOHN SMITH' company='DIRECTOR, ABC PTE LTD' />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialBox image='Rectangle 4503.png' description='We are happy to have chosen Red Dot Innovative to
digitize our store operations. The solutions they suggested
along with it time bound implementation helped us to
provide great experience to our customers. They are truly
the best technology partner out there.' name='JOHN SMITH' company='DIRECTOR, ABC PTE LTD' />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialBox image='Rectangle 4503.png' description='We’re very pleased with the SaaS solution you built for
our EV revolution. Everything works well and you’ve
provided great service.' name='JOHN SMITH' company='DIRECTOR, ABC PTE LTD' />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialBox image='Rectangle 4503.png' description='Upon starting with my new health-tech startup idea, I
turned to Red Dot Innovative to design and implement
new technologies as per our requirement. Red Dot
Innovative managed the project professionally and
efficiently which helped me got my idea turned into
reality. I continue to depend on Red Dot Innovative today
for all of my technology needs because when something
works, I stick with it. Thank you Red Dot Innovative, for a
job well done.'  name='JOHN SMITH' company='DIRECTOR, ABC PTE LTD' />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialBox image='Rectangle 4503.png' description='Red Dot Innovative has really come through for us in
supporting all our for our data analytics needs. We will
continue to rely on Red Dot Innovative to support our UI
Consultancy requirement.'   name='JOHN SMITH' company='DIRECTOR, ABC PTE LTD' />
            </SwiperSlide>
            <SwiperSlide>
              <TestimonialBox image='Rectangle 4503.png' description='Red Dot Innovative has really come through for us in
supporting all our for our data analytics needs. We will
continue to rely on Red Dot Innovative to support our UI
Consultancy requirement.' name='JOHN SMITH' company='DIRECTOR, ABC PTE LTD' />
            </SwiperSlide>
          </Swiper>
        </div>
      </div> */}
      <div className="snap-start">
        <Footer />
      </div>
      <div className="snap-start">
        <FooterBottom />
      </div>
    </div>
  );
}
