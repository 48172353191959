import React from 'react'
import { useNavigate } from 'react-router-dom'
import IndustriesBox from './IndustriesBox'

export default function IndustriesInner({foodRef}) {
    const navigate = useNavigate()

    const handleNavigate = (url) =>{
        navigate(`/industries/${url}`)
    }
    return (
        <>
            <div onClick={()=>{handleNavigate("foodandbeverages")}} ref={foodRef} className="snap-start cursor-pointer px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white py-16">
                <IndustriesBox img="food-&-beverages.jpeg" title='FOOD & BEVERAGES' subtitle='Reimagining the food industry with design innovations' detail="The food and beverage industry is becoming increasingly competitive, as consumers have more options and higher expectations for the products they buy. Companies can use data analytics and AI to gain a deeper understanding of consumer preferences and behaviour, enabling them to create products that meet their needs and stand out from the competition. Furthermore, it is essential for food and beverage companies to ensure food safety and quality for their success. IT tools, such as food safety management systems and real-time monitoring of production processes, can help companies meet regulatory standards and provide consumers with high-quality products. Nowadays, it is becoming increasingly important for food and beverage companies to engage with customers in new and innovative ways. By utilizing design thinking, companies can create unique and memorable customer experiences that will increase brand loyalty and drive sales." />
            </div>
            <div onClick={()=>{handleNavigate("logisticandsupplychain")}} className="snap-start cursor-pointer px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white py-16">
                <IndustriesBox img="logistics.jpeg" title='LOGISTICS & SUPPLY CHAIN' subtitle='Simplifying process of logistics & supply chain through technology' detail="Logistics and warehousing come with their own set of challenges. One of the biggest challenges is ensuring that all stakeholders have visibility into the supply chain. Tools such as blockchain and real-time tracking can help increase transparency and improve collaboration among all parties, reducing the risk of supply chain disruptions. Additionally, inventory management is critical for logistics and warehousing companies. Companies can improve accuracy and efficiency in inventory tracking and forecasting through the use of automation and artificial intelligence, thereby reducing waste and increasing profitability. In addition, last-mile delivery is a hard problem to crack for most market players in the logistics and warehousing sector. The final delivery to customers can be a major pain point in logistics and warehousing. Companies can improve delivery times and accuracy by using technologies such as autonomous delivery vehicles and real-time tracking, thus providing customers with a better delivery experience. Thus, the use of IT and digitization can help logistics and warehousing companies overcome their technological challenges and stay ahead of the competition. By leveraging technology, companies can improve operations, increase efficiency, and ensure customer satisfaction, thus driving growth and success."/>
            </div>
            <div onClick={()=>{handleNavigate("healthcare")}} className="snap-start cursor-pointer px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white py-16">
                <IndustriesBox img="healthcare.jpeg" title='HEALTHCARE' subtitle='Simplifying process of logistics & supply chain through technology' detail="Digital transformation is conquering the healthcare industry. Artificial intelligence, robotics, big data, and other related technologies drive healthcare growth. These advances improve patient outcomes, simplify operations, and reduce costs. Artificial intelligence and robotics are used in medical analysis and surgery, while big data drives informed decision-making. The B2B model fosters collaboration and profitability. As a result, financial institutions are investing in health technology, and following COVID-19, all stakeholders are putting a lot of emphasis on health services."/>
            </div>
            <div onClick={()=>{handleNavigate("retail")}} className="snap-start cursor-pointer px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white py-16">
                <IndustriesBox img="Retail.jpg" title='RETAIL' subtitle='Empowering Agriculture with automating processes.' detail="In today's fast-paced and competitive marketplace, retail businesses face several major pain points. One of the biggest challenges in today's fast-paced and competitive marketplace is delivering a seamless and personalized shopping experience. For customers in the B2B and B2C market segments. Customers demand easy access to information, a fast and easy checkout process, and personalized recommendations and offers. To meet these challenges, retailers worldwide are using technology and data to stay competitive and deliver the best possible retail experience. Collecting and analyzing customer data, it enables retailers to run targeted marketing campaigns, streamline their supply chain processes, and improve their in-store and online customer experience. Artificial intelligence and machine learning algorithms are also being used to provide real-time insights and recommendations to customers so they can make informed purchasing decisions. In addition, the integration of advanced technologies such as augmented reality, virtual reality and omnichannel retailing provides new and innovative ways to interact with customers with brands and products. However, it is important for retailers to strike a balance between using technology and data to enhance the customer experience while protecting their privacy. Dealers must be aware of this. Adequate privacy and data protection policies are in place and they are transparent about how customer data is used. As a result, retailers face major challenges in delivering superior products and customer shopping experiences. Using technology and data, they can stay competitive and deliver the best retail experience while balancing customer privacy concerns." />
            </div>
            <div onClick={()=>{handleNavigate("constructionAndRealEstate")}} className="snap-start cursor-pointer px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white py-16">
                <IndustriesBox img="Construction & Real Estate.jpg" title='Construction and Real-estate ' subtitle='Empowering Agriculture with automating processes.' detail="The construction and real estate industry is undergoing digital transformation, with a shift in focus to providing a seamless and enhanced customer experience in this highly competitive industry. In today's market, clients expect speed, convenience and personal service that traditional construction and real estate firms struggle to provide. To meet these challenges, companies must use technology and data to streamline their operations, improve communication and collaboration, and provide a more personalized experience to their customers. The use of virtual and extended reality technology is revolutionizing the way customers interact with real estate, providing them with a more immersive and interactive experience. However, technology adoption in the construction and real estate industry is still in its early stages, and companies must grapple with privacy and cybersecurity challenges. CXOs need to ensure they have strong data protection and privacy policies and are transparent about how they use customer data. The construction and real estate industry is at the forefront of digital transformation, using technology and data to provide opportunities to enhance customer experience, streamline operations and remain competitive in a rapidly evolving market." />
            </div>
            <div onClick={()=>{handleNavigate("hospitality")}} className="snap-start cursor-pointer px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white py-16">
                <IndustriesBox img="hospitality.jpg" title='HOSPITALITY' subtitle='Empowering Agriculture with automating processes.' detail='One of the most significant challenges in the hospitality industry is rising labour costs. By implementing automation and self-service technology such as touch-screen ordering systems and mobile check-ins, hospitality businesses can reduce labour costs while still providing a seamless customer experience. These systems can also collect useful customer data, which can be analysed to improve operations and efficiency. Another issue is that traditional hospitality businesses are finding it increasingly difficult to attract and retain customers as online travel agencies and booking platforms grow in popularity. By leveraging digital marketing and social media, hospitality businesses can reach a larger audience, raise brand awareness, and provide more personalized customer experiences. The hospitality industry must address the importance of providing a consistent customer experience. From check-in to check-out, mobile apps and wearable devices are being used to improve the customer experience. Virtual assistants, for example, are now available in some hotels and can be used to control room features, make restaurant reservations, and access information about the hotel and its surroundings.' />
            </div>
        </>
    )
}
