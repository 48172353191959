import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import ReactTypingEffect from "react-typing-effect";
import Footer from "../components/Footer/Footer";
import FooterBottom from "../components/Footer/FooterBottom";
import { handleScroll } from "../components/Helper/Helper";
import IndustriesBox from "../components/Industries/IndustriesBox";
import IndustriesInner from "../components/Industries/IndustriesInner";
// import ServiceInner from '../components/Services/ServiceInner';
import ServiceInnerBox from "../components/Services/ServicesInnerBox";
import StaffPageStyle from "../pages/StaffPageStyle.css";

const accordionItems = [
  {
    id: 1,
    heading: "1. What is staff augmentation?",
    content:
      "Staff augmentation is a business strategy where organizations hire external talent or resources to supplement their in-house teams. These resources can be temporary or project-based, and they work alongside existing employees to meet specific needs or address skill gaps.",
    styleHeading: true,
  },
  {
    id: 2,
    heading: "2. Why would a company consider staff augmentation?",
    content: `Companies use staff augmentation to:<br>
    <ul>
    <li>- Quickly access specialised skills and expertise.</li>
    <li>- Manage fluctuating workloads without committing to permanent hires.</li>
    <li>- Save time and resources on recruitment and onboarding.</li>
    <li>- Enhance flexibility in scaling their workforce up or down as needed.</li>
    </ul>`,
  },
  {
    id: 3,
    heading: "3. How does staff augmentation differ from outsourcing?",
    content:
      "Staff augmentation involves hiring external professionals who work alongside the in-house team, often on-site. Outsourcing typically involves contracting an external firm to handle specific tasks or processes remotely, with minimal interaction with the in-house team.",
  },
  {
    id: 4,
    heading: "4. What types of roles can be filled through staff augmentation?",
    content:
      "Staff augmentation can be used for various roles, including software developers, project managers, data analysts, designers, customer support agents, and more. It depends on the specific needs of the company.",
  },

  {
    id: 5,
    heading: "5. Is staff augmentation cost-effective?",
    content:
      "Staff augmentation can be used for both short-term and long-term projects. It offers flexibility to bring in additional resources for short-duration tasks or maintain an extended presence for ongoing projects.",
  },
  {
    id: 6,
    heading: "6. What are the benefits of staff augmentation?",
    content:
      "Benefits include access to specialised skills, faster project delivery, cost savings compared to permanent hires, reduced administrative burdens, and scalability.",
  },
  {
    id: 7,
    heading:
      "8. Are there legal and compliance considerations when using Staff Augmentation?",
    content:
      "Yes, there are legal and compliance considerations, including contracts, non-disclosure agreements, and adherence to labor laws, that should be carefully addressed when engaging in staff augmentation.",
  },
];

function Accordion() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    setActiveAccordion(activeAccordion === id ? null : id);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {accordionItems.map((item) => (
        <div
          style={{
            padding: "20px",
            background: "#fff",
            borderBottom: "1px solid #EFECEB",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
          }}
          key={item.id}
          className={`accordion ${
            activeAccordion === item.id ? "expanded" : ""
          }`}
          onClick={() => toggleAccordion(item.id)}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className="accordion-heading">
              <h2>{item.heading}</h2>
            </div>
            {activeAccordion === item.id && (
              <div
                className="accordion-content"
                style={{
                  marginTop: "30px",
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

function TabbedContent() {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handlePreviousTab = () => {
    setActiveTab((prevTab) => (prevTab > 1 ? prevTab - 1 : prevTab));
  };

  const handleNextTab = () => {
    setActiveTab((prevTab) => (prevTab < 4 ? prevTab + 1 : prevTab));
  };

  return (
    <div>
      <div
        className="tab-buttons"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingBottom: "20px",
        }}
      >
        <button
          onClick={() => handleTabClick(1)}
          className={activeTab === 1 ? "active" : ""}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flex: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-10px",
              width: "25px",
              height: "25px",
              background: activeTab === 1 ? "#1D4ED8" : "#D2D2D2",
              borderRadius: "100px",
            }}
          ></div>
          <div
            style={{
              borderTop:
                activeTab === 1 ? "2px solid #1D4ED8" : "2px solid #D2D2D2",
              width: "100%",
            }}
          >
            <div
              style={{
                color: activeTab === 1 ? "#1D4ED8" : "#D2D2D2",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "25px",
              }}
            >
              Identify the need
            </div>
            <div
              style={{
                color: activeTab === 1 ? "#1D4ED84D" : "#D2D2D2",
                fontSize: "40px",
              }}
            >
              01
            </div>
          </div>
        </button>

        <button
          onClick={() => handleTabClick(2)}
          className={activeTab === 2 ? "active" : ""}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flex: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-10px",
              width: "25px",
              height: "25px",
              background: activeTab === 2 ? "#1D4ED8" : "#D2D2D2",
              borderRadius: "100px",
            }}
          ></div>

          <div
            style={{
              borderTop:
                activeTab === 2 ? "2px solid #1D4ED8" : "2px solid #D2D2D2",
              width: "100%",
            }}
          >
            <div
              style={{
                color: activeTab === 2 ? "#1D4ED8" : "#D2D2D2",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "25px",
              }}
            >
              Define Requirements
            </div>
            <div
              style={{
                color: activeTab === 2 ? "#1D4ED84D" : "#D2D2D2",
                fontSize: "40px",
              }}
            >
              02
            </div>
          </div>
        </button>

        <button
          onClick={() => handleTabClick(3)}
          className={activeTab === 3 ? "active" : ""}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flex: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-10px",
              width: "25px",
              height: "25px",
              background: activeTab === 3 ? "#1D4ED8" : "#D2D2D2",
              borderRadius: "100px",
            }}
          ></div>

          <div
            style={{
              borderTop:
                activeTab === 3 ? "2px solid #1D4ED8" : "2px solid #D2D2D2",
              width: "100%",
            }}
          >
            <div
              style={{
                color: activeTab === 3 ? "#1D4ED8" : "#D2D2D2",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "25px",
              }}
            >
              Search for Talent
            </div>
            <div
              style={{
                color: activeTab === 3 ? "#1D4ED84D" : "#D2D2D2",
                fontSize: "40px",
              }}
            >
              03
            </div>
          </div>
        </button>

        <button
          onClick={() => handleTabClick(4)}
          className={activeTab === 4 ? "active" : ""}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            flex: "100%",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-10px",
              width: "25px",
              height: "25px",
              background: activeTab === 4 ? "#1D4ED8" : "#D2D2D2",
              borderRadius: "100px",
            }}
          ></div>

          <div
            style={{
              borderTop:
                activeTab === 4 ? "2px solid #1D4ED8" : "2px solid #D2D2D2",
              width: "100%",
            }}
          >
            <div
              style={{
                color: activeTab === 4 ? "#1D4ED8" : "#D2D2D2",
                fontSize: "20px",
                fontWeight: "bold",
                marginTop: "25px",
              }}
            >
              Select and Onboard
            </div>
            <div
              style={{
                color: activeTab === 4 ? "#1D4ED84D" : "#D2D2D2",
                fontSize: "40px",
              }}
            >
              04
            </div>
          </div>
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button className="tab-change-btn"
        
          style={{
            marginLeft: "50px",
            border: "1px solid #292D34",
            borderRadius: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
          onClick={handlePreviousTab}
        >
          <img
            className="tab-change-btn-icon"
            src="/prevBtn.svg"
            alt=""
            style={{ width: "20px", height: "auto" }}
          />
        </button>

        <div className="tab-content">
          {activeTab === 1 && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src="/identifyNeed.png"
                    alt=""
                    style={{
                      width: "50px",
                      margibBottom: "50px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#1D4ED8",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Identify the Need
                </div>
                <div
                  className="tab-content-text"
                  style={{
                    textAlign: "center",
                    marginTop: "25",
                    paddingLeft: "250px",
                    paddingRight: "250px",
                  }}
                >
                  First, you need to identify a specific skill gap or project
                  requirement within your organisation. This could be due to a
                  temporary increase in workload, the need for specialised
                  expertise, or the desire to accelerate a project's completion.
                </div>
              </div>
            </div>
          )}

          {activeTab === 2 && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src="/defineReq.png"
                    alt=""
                    style={{
                      width: "50px",
                      margibBottom: "50px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#1D4ED8",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Define Requirements
                </div>
                <div className="tab-content-text"
                  style={{
                    textAlign: "center",
                    marginTop: "25",
                    paddingLeft: "250px",
                    paddingRight: "250px",
                  }}
                >
                  Clearly define the skills, experience, and qualifications
                  required for the external staff you plan to bring on board.
                  This includes specifying the roles, responsibilities, and
                  project objectives.
                </div>
              </div>
            </div>
          )}

          {activeTab === 3 && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src="/talent.png"
                    alt=""
                    style={{
                      width: "50px",
                      margibBottom: "50px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#1D4ED8",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Search for Talent
                </div>
                <div className="tab-content-text"
                  style={{
                    textAlign: "center",
                    marginTop: "25",
                    paddingLeft: "250px",
                    paddingRight: "250px",
                  }}
                >
                  Once you've defined your requirements, you can start searching
                  for external talent. This can be done through various means,
                  including job boards, freelancing platforms, recruitment
                  agencies, or direct referrals.
                </div>
              </div>
            </div>
          )}

          {activeTab === 4 && (
            <div>
              <div 
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src="/onboard.png"
                    alt=""
                    style={{
                      width: "50px",
                      margibBottom: "50px",
                    }}
                  />
                </div>
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#1D4ED8",
                    textAlign: "center",
                    marginTop: "20px",
                  }}
                >
                  Select and Onboard
                </div>
                <div className="tab-content-text"
                  style={{
                    textAlign: "center",
                    marginTop: "25",
                    paddingLeft: "250px",
                    paddingRight: "250px",
                  }}
                >
                  Evaluate potential candidates or providers based on their
                  qualifications, experience, and cultural fit with your
                  organization. Once you've identified the right individuals or
                  teams, onboard them by providing access to necessary tools,
                  systems, and training.
                </div>
              </div>
            </div>
          )}
        </div>

        <button className="tab-change-btn"
          style={{
            marginRight: "50px",
            border: "1px solid #292D34",
            borderRadius: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
          onClick={handleNextTab}
        >
          <img
            className="tab-change-btn-icon"
            src="/nextBtn.svg"
            alt=""
            style={{ width: "20px", height: "auto" }}
          />
        </button>
      </div>
    </div>
  );
}

export default function StaffPage({ bg, setVisible, executeScroll }) {
  const foodRef = useRef(null);
  let serviceIndBody = document.getElementsByClassName("serviceInd_body")[0];
  const [prevScrollpos, setPrevScrollPos] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!serviceIndBody) {
      serviceIndBody = document.getElementsByClassName("serviceInd_body")[0];
    }
    serviceIndBody?.addEventListener("scroll", () =>
      handleScroll(serviceIndBody, prevScrollpos, setPrevScrollPos)
    );
    return () =>
      serviceIndBody?.removeEventListener("scroll", () =>
        handleScroll(serviceIndBody, prevScrollpos, setPrevScrollPos)
      );
  }, [serviceIndBody?.scrollTop]);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div className="h-screen w-full t-0 serviceInd_body absolute">
      <div className="mx-4 pt-24 snap-start aboutUsHero bg-contain h-screen serviceInd bg-no-repeat msm:bg-right bg-center mvsm:mx-6 md:mx-20 msm:mx-10">
        <h1 className="text-2xl mt-4  vsm:text-3xl sm:text-4xl mvsm:text-5xl md:text-7xl  xl:text-8xl text-text/heading font-black">
          Redefine industries <br /> through innovation
        </h1>
        {/* <p className='mt-14 text-text/heading font-normal text-xl'>
        Logistics, food & beverages, enterprise solutions, <br /> lifestyle, healthcare and social, we innovate it all
      </p> */}
        <div
          onClick={() => {
            executeScroll(foodRef);
          }}
          className=" border-2 border-secondary-2 rounded-full h-12 w-7 cursor-pointer left-1/2 -translate-x-1/2 absolute bottom-10 lg:mt-10"
        >
          <div className="border bg-secondary-2 border-secondary-2 h-2 rounded-full mx-auto w-fit mt-2 animate-curser_upDown"></div>
        </div>
      </div>
      <>
        <div className="service-desc-container"
          style={{
            display: "flex",
            flex: "100%",
            paddingLeft: "75px",
            paddingRight: "75px",
          }}
        >
          <div
            style={{
              width: "30%",
            }}
          >
            <img
              src="/staff augm.png"
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginLeft: "50px",
              width: "70%",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#EF3340",
                marginBottom: "20px",
              }}
            >
              Staff Augmentation
            </div>
            <div>
              Staff augmentation is a business strategy in which a company
              temporarily hires external personnel, typically on a contract
              basis, to supplement its existing workforce and meet specific
              project or skillset needs. This approach is commonly used in
              industries where the demand for skilled professionals fluctuates
              or for organisations looking to scale up or down quickly without
              the long-term commitment associated with traditional hiring.This
              approach allows businesses to scale their workforce up or down as
              needed, access specialized skills, and reduce costs compared to
              traditional hiring methods.Organizations hire individuals with
              specific skills or expertise that are needed for a particular
              project. These professionals can include developers, designers,
              project managers, analysts, or other specialists.
            </div>
          </div>
        </div>

        <div className="service-desc-container-mobile"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "100%",
            paddingLeft: "75px",
            paddingRight: "75px",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <img
              src="/staff augm.png"
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="service-desc-text"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                fontWeight: "bold",
                color: "#EF3340",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Staff Augmentation
            </div>
            <div style={{textAlign:'center'}}>
              Staff augmentation is a business strategy in which a company
              temporarily hires external personnel, typically on a contract
              basis, to supplement its existing workforce and meet specific
              project or skillset needs. This approach is commonly used in
              industries where the demand for skilled professionals fluctuates
              or for organisations looking to scale up or down quickly without
              the long-term commitment associated with traditional hiring.This
              approach allows businesses to scale their workforce up or down as
              needed, access specialized skills, and reduce costs compared to
              traditional hiring methods.Organizations hire individuals with
              specific skills or expertise that are needed for a particular
              project. These professionals can include developers, designers,
              project managers, analysts, or other specialists.
            </div>
          </div>
        </div>


        <div
          className="how-it-works-container"
          style={{
            backgroundColor: "#FAFAFA",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-black main_heading">
            How It Works?
          </h2>

          <div className="how-it-works-text"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              textAlign: "center",
              width: "60%",
              marginBottom: "30px",
            }}
          >
            Staff augmentation is a business strategy that involves
            supplementing your existing in-house team with external
            professionals or resources to meet specific project or skill
            requirements.
          </div>

          <div>
            <TabbedContent />
          </div>
        </div>

        <div
          className="staff-service-offering-container"
          style={{
            backgroundColor: "#FAFAFA",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-black main_heading">
            Staff Augmentation Services: Our offerings
          </h2>

          <div className="service-offering-text"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              textAlign: "center",
              width: "60%",
            }}
          >
            Our Staff Augmentation services are tailor-made and flexible for
            your business requirements.
          </div>

          <div className="unique-grid py-3 msm:py-16 msm:px-16 xs:px-5 gap-12 grid-cols-3 msm:grid-cols-3 xs:grid-cols-1">
            <div
              className="offering-card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <img
                  src="/dedicatedTeam.png"
                  style={{ width: "40px", height: "auto" }}
                  alt=""
                />
              </div>
              <div style={{ fontWeight: "700" }}>Dedicated On-Demand Team</div>
              <div style={{ fontSize: "12px" }}>
                We enable our customers to recruit experienced, certified,
                talented, certified and experienced on-demand tech teams for
                timely project progress.
              </div>
            </div>
            <div
              className="offering-card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <img
                  src="/agile.png"
                  style={{ width: "40px", height: "auto" }}
                  alt=""
                />
              </div>
              <div style={{ fontWeight: "700" }}>Agile, Flexible Team</div>
              <div style={{ fontSize: "12px" }}>
                We promptly deploy a temporary team based on the specific
                requirements for urgent projects.
              </div>
            </div>
            <div
              className="offering-card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <img
                  src="/payAsyou.png"
                  style={{ width: "40px", height: "auto" }}
                  alt=""
                />
              </div>
              <div style={{ fontWeight: "700" }}>Pay as you go Model</div>
              <div style={{ fontSize: "12px" }}>
                We provide our clients with contractual, on-demand workers as
                and when needed, who will be paid based on projects or hours.
              </div>
            </div>
            <div
              className="offering-card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <img
                  src="/contracts.png"
                  style={{ width: "40px", height: "auto" }}
                  alt=""
                />
              </div>
              <div style={{ fontWeight: "700" }}>Contracts & OKRs</div>
              <div style={{ fontSize: "12px" }}>
                We enable our customers to define and implement the NDAs for the
                extended team, Objectives and Key Results (OKRs), and work
                contracts.
              </div>
            </div>
            <div
              className="offering-card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <img
                  src="/recruitment.png"
                  style={{ width: "40px", height: "auto" }}
                  alt=""
                />
              </div>
              <div style={{ fontWeight: "700" }}>
                Managed Recruitment Services
              </div>
              <div style={{ fontSize: "12px" }}>
                We will handle every aspect related to HR Management for your
                business: on-demand recruitment, profiling, selection,
                onboarding, & more.
              </div>
            </div>
            <div
              className="offering-card"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <img
                  src="/support.png"
                  style={{ width: "40px", height: "auto" }}
                  alt=""
                />
              </div>
              <div style={{ fontWeight: "700" }}>On-Boarding Support</div>
              <div style={{ fontSize: "12px" }}>
                Our specialized HR team will handle all aspects of the
                onboarding process for the new employees and help them
                transition.
              </div>
            </div>
          </div>
        </div>

        <div
          className="tech-stack-container"
          style={{
            backgroundColor: "#FAFAFA",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 className="text-Primary-Colour tracking-widest font-semibold ">
            INTEGRATIONS
          </h4>

          <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-black main_heading">
            The tech stack designed for building competitive products
          </h2>

          <div className="tech-stack-text"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              textAlign: "center",
              width: "60%",
            }}
          >
            A "tech stack" refers to the combination of technologies and tools
            that are used to build and operate a software application or system.
          </div>

          <div className="tech-grid py-3 msm:py-16 msm:px-16 sm:px-5 gap-24 grid-cols-6 msm:grid-cols-6 sm:gap-12 sm:grid-cols-4"> 
            <div>
              <img src="/js.png" alt="" />
            </div>
            <div>
              <img src="/swift.png" alt="" />
            </div>
            <div>
              <img src="/react.png" alt="" />
            </div>
            <div>
              <img src="/solidarity.png" alt="" />
            </div>
            <div>
              <img src="/vuew.png" alt="" />
            </div>
            <div>
              <img src="/firebase.png" alt="" />
            </div>
            <div>
              <img src="/flutter.png" alt="" />
            </div>
            <div>
              <img src="/tailwind2.png" alt="" />
            </div>
            <div>
              <img src="/php.png" alt="" />
            </div>
            <div>
              <img src="/klane.png" alt="" />
            </div>
            <div>
              <img src="/gitLab.png" alt="" />
            </div>
            <div>
              <img src="/bootstrap.png" alt="" />
            </div>
          </div>
        </div>

        <div
          className="staff-comparison-container"
          style={{
            backgroundColor: "#FAFAFA",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 className="text-Primary-Colour tracking-widest font-semibold ">
            BENEFITS
          </h4>

          <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-black main_heading">
            Red Dot Innovative Staff vs Local Staff
          </h2>

          <div className="staff-comparison-text"
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              textAlign: "center",
              width: "60%",
            }}
          >
            How Red Dot Innovative helps businesses hire IT staff so that you
            can scale your business effortlessly, without worrying about
            retention issues.
          </div>

          <div
            className="staff-compare-card-container grid py-3 msm:py-16 msm:px-16 sm:px-5 gap-3 grid-cols-3 msm:grid-cols-3 sm:grid-cols-1"
            style={{ width: "100%" }}
          >
            <div
              className="staff-compare-card"
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
                width: "100%",
                border: "6px solid #fff",
                position: "relative",
              }}
            >
              <div
                className="card-header"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ background: "#fff", padding: "2px" }}>
                  <div style={{ fontSize: "12px", color: "#fff" }}>
                    whatever text
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Hiring Time
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Joining Time
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  ESOPs
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Scaling Up
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Scaling Down
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Transparency
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Happiness Score
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Severance
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "20px", paddingTop: "20px" }}>
                  <img
                    src="/rightIcon.svg"
                    alt=""
                    style={{ marginRight: "20px" }}
                  />
                </div>
                <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                  Compliant Management
                </div>
              </div>
            </div>

            <div
              className="staff-compare-card"
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
                width: "100%",
                border: "6px solid #EF3340",
                position: "relative",
              }}
            >
              <div
                className="card-header"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: "#EF3340",
                    padding: "2px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    RED DOT STAFF
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  It usually takes up to 7 days to hire
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Candidate joins within 2-3 days after selection
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>No ESOP needed</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>A swift 3-day process</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>Just send one email</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Reports and monthly reviews are a click away
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Our Customer (NPS) is a strong 8.5
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  No Severance when letting people go
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Just one invoice and a simple process
                </div>
              </div>
            </div>

            {/* local staff */}

            <div
              className="staff-compare-card"
              style={{
                background: "#fff",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px #0000001a",
                width: "100%",
                border: "6px solid #2188FF",
                position: "relative",
              }}
            >
              <div
                className="card-header"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    background: "#2188FF",
                    padding: "2px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      fontWeight: "bold",
                    }}
                  >
                    LOCAL STAFF
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Generally takes up to 60-70 days to hire
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Takes 30-45 days to join after the selection
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Top engineers often want ESOPs
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>A slow 60-day process</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Risk to reputation, team morale
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Need an extra team or tools to manage
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Managers struggle to improve productivity
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Usually 2-3 month of severance
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  borderBottom: "1px solid #DBD9D2",
                }}
              >
                <div style={{ paddingBottom: "23px", paddingTop: "20px" }}>
                  <img src="/tick.svg" alt="" style={{ marginRight: "20px" }} />
                </div>
                <div style={{ fontSize: "14px" }}>
                  Managing PF, labor laws, laptops, Wifi, leaves, etc.
                </div>
              </div>
            </div>
          </div>

          <div
            className="staff-compare-card-conatiner-mobile"
            style={{ width: "100%" }}
          >
            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                Red Dot Innovative Staff
              </div>
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "15px",
                  textAlign: "center",
                }}
              >
                Local Staff
              </div>
            </div>

            {/* -------------------------------- */}

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="cards-heading"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                Hiring Time
              </div>
            </div>

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#EF3340",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>It usually takes up to 7 days to hire</div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#1D4ED8",
                  padding: "10px",
                  borderRadius: "6px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Generally takes up to 60-70 days to hire</div>
              </div>
            </div>

            {/* ------------------------------------ */}

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="cards-heading"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                Joining Time
              </div>
            </div>

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#EF3340",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Candidate joins within 2-3 days after selection</div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#1D4ED8",
                  padding: "10px",
                  borderRadius: "6px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Takes 30-45 days to join after the selection</div>
              </div>
            </div>

            {/* ------------------------------------ */}

            
            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="cards-heading"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                ESOPs
              </div>
            </div>

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#EF3340",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>No ESOP needed</div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#1D4ED8",
                  padding: "10px",
                  borderRadius: "6px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Top engineers often want ESOPs</div>
              </div>
            </div>


            {/* ------------------------------------ */}

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="cards-heading"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                Transparency
              </div>
            </div>

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#EF3340",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Reports and monthly reviews are a click away</div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#1D4ED8",
                  padding: "10px",
                  borderRadius: "6px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Need an extra team or tools to manage</div>
              </div>
            </div>


            {/* ------------------------------------ */}

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="cards-heading"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                Happiness Score
              </div>
            </div>

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#EF3340",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Our Customer (NPS) is a strong 8.5</div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#1D4ED8",
                  padding: "10px",
                  borderRadius: "6px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Managers struggle to improve productivity</div>
              </div>
            </div>


            {/* ------------------------------------ */}


            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="cards-heading"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                Severance
              </div>
            </div>

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#EF3340",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>No Severance when letting people go</div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#1D4ED8",
                  padding: "10px",
                  borderRadius: "6px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Usually 2-3 month of severance</div>
              </div>
            </div>


            {/* ------------------------------------ */}

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                className="cards-heading"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                Compliant Management 
              </div>
            </div>

            <div
              className="staff-compare-single-row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#EF3340",
                  padding: "10px",
                  borderRadius: "6px",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Just one invoice and a simple process</div>
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  fontWeight: "bold",
                  fontSize: "10px",
                  color: "#fff",
                  background: "#1D4ED8",
                  padding: "10px",
                  borderRadius: "6px",
                  marginLeft: "10px",
                }}
              >
                <div style={{ marginRight: "10px" }}>
                  <img src="/tickWhite.svg" alt="" />
                </div>
                <div>Managing PF, labor laws, laptops, Wifi, leaves, etc.</div>
              </div>
            </div>



          </div>
        </div>

        <div
          className="brands-container"
          style={{
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 className="text-Primary-Colour tracking-widest font-semibold ">
            BRANDS
          </h4>

          <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-black main_heading">
            Trusted by Business: A Proven Track Record of Excellence
          </h2>

          <div
            className="grid py-3 sm:px-5 grid-cols-12 msm:grid-cols-12 sm:grid-cols-12"
            style={{ display: "flex" }}
          >
            <div style={{ width: "100%" }}>
              <img
                src="/brands.png"
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          </div>
        </div>

        <div
          className="faq-container"
          style={{
            backgroundColor: "#FAFAFA",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4 className="text-Primary-Colour tracking-widest font-semibold ">
            FAQ
          </h4>

          <h2
            style={{ marginBottom: "30px" }}
            className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-black main_heading"
          >
            Frequently Asked Questions
          </h2>

          <Accordion />
        </div>
      </>
      <div className="snap-start">
        <Footer />
      </div>
      <div className="snap-start">
        <FooterBottom />
      </div>
    </div>
  );
}
