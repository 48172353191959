import React from 'react'

export default function IndustriesBox({img,title,subtitle,detail}) {
  return (
      <div className='flex gap-7 lg:flex-row sm:flex-col-reverse '>
        <img className='w-[100vw]  min-h-full object-cover sm:w-[100vw] lg:w-1/3' src={img} alt="" />
        <div className='flex flex-col gap-9 '>
          <h4 className="text-Primary-Colour tracking-widest font-semibold sm:text-center lg:text-left">{title}</h4>
          <p className='text-4xl font-black text-secondary-2 sm:text-center lg:text-left'>{subtitle}</p>
          <p className='font-medium text-xl text-secondary-2 sm:text-center lg:text-left'>{detail}</p>
        </div>
      </div>
  )
}
