import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function IndustriesBox({img,title,subtitle,detail,bg,foodRef,link}) {
  const navigate = useNavigate()
  const handleNavigate = (url) =>{
    navigate(`/Services${url}`)
}

  return (
      <div onClick={()=>handleNavigate(link)} ref={foodRef} className='flex flex-col lg:flex-row gap-7 serviceBox' style={{cursor:'pointer'}}>
        <img className={`w-24 h-24 rounded-full object-cover ${bg}`} src={img} alt="" />
        <div className='flex flex-col gap-3'>
          <h4 className="text-Primary-Colour break-normal h-14 tracking-widest font-medium text-xl ">{title}</h4>
          {/* <p className='text-4xl font-black text-secondary-2'>{subtitle}</p> */}
          <p className='font-medium text-base text-white '>{detail} <span style={{color:"grey",cursor:"pointer"}} >Read More</span></p>
        </div>
        {/* <div className="text-on-image">
          <button onClick={()=>handleNavigate(link)}>Read More</button>
        </div> */}
      
      </div>
  )
}
