import React from 'react'
import { useNavigate } from 'react-router-dom'
import ServiceBox from './ServiceBox'

export default function ServiceInner({foodRef}) {
    const navigate = useNavigate()

    const handleNavigate = (url) =>{
        navigate(`/Services/${url}`)
    }

    // onClick={()=>{handleNavigate("product")}}
    // onClick={()=>{handleNavigate("webDevelopment")}}
    // onClick={()=>{handleNavigate("iot")}}

    // onClick={()=>{handleNavigate("UIdesign")}}
    // onClick={()=>{handleNavigate("DataAnalytics")}}
    // onClick={()=>{handleNavigate("Enterprise")}}

    return (
        <div className=' px-0 msm:px-10'>
            <div  ref={foodRef} className='flex serviceInnerFlex'>
            <div   style={{border:"1px solid #cbcbcb" }}className=" bg-[#25282B] rounded-md snap-start py-3 px-5 mx-3 my-3 w-1/3  bg-white serviceInnerFlexBox ">
                <ServiceBox  bg="bg-my-yellow" link="/product" img="Product Developement Single.png" title='Product Development' detail="Whether you are a business owner who wants... " />
            </div>
            <div  style={{border:"1px solid #cbcbcb" }} className=" bg-[#25282B] rounded-md snap-start py-3 px-5 mx-3 my-3 w-1/3  serviceInnerFlexBox bg-white">
                <ServiceBox bg="bg-my-blue" link="/webDevelopment" img="web developement Single.png" title='Web Development' detail="Hosting your business online is first step to experience..."/>
            </div>
            <div  style={{border:"1px solid #cbcbcb" }} className="bg-[#25282B] rounded-md snap-start py-3 px-5 mx-3 my-3 w-1/3  serviceInnerFlexBox  bg-white ">
                <ServiceBox bg="bg-Primary-Colour" link="/iot" img="IOT Single.png" title='Internet Of Things' detail="Deployment of the internet of things across industries has gained... "/>
            </div>
            </div>
            <div  className='flex serviceInnerFlex'>
            <div style={{border:"1px solid #cbcbcb" }} className=" bg-[#25282B] rounded-md snap-start py-3 px-5 mx-3 my-3 w-1/3  serviceInnerFlexBox bg-white ">
                <ServiceBox bg="bg-my-green" link="/UIdesign" img="ui design consultancy Single.png" title='UI Design Consultancy'  detail='We hold deep expertise in UI architecture to
build seamless...'/>
            </div>
            <div   style={{border:"1px solid #cbcbcb" }} className="bg-[#25282B] rounded-md snap-start py-3 px-5 mx-3 my-3 w-1/3  serviceInnerFlexBox bg-white ">
                <ServiceBox  bg="bg-my-blue" link="/DataAnalytics" img="Data analytics Single.png" title='Data Analytics'  detail='Data is the new oil for this as well as the
upcoming generations...
'/>
            </div>
            <div style={{border:"1px solid #cbcbcb" }} className=" bg-[#25282B] rounded-md snap-start py-3 px-5 mx-3 my-3 w-1/3  serviceInnerFlexBox bg-white ">
                <ServiceBox  bg="bg-my-green" link="/professional-services" img="staff augm.png" title='Staff Augmentation'  detail='Staff augmentation is a business strategy in which a company...'/>
            </div>
            </div>
        </div>
    )
}
