import React, { useEffect, useRef, useState } from 'react'
import ReactTypingEffect from 'react-typing-effect';
import Footer from "../components/Footer/Footer";
import FooterBottom from "../components/Footer/FooterBottom";
import { handleScroll } from '../components/Helper/Helper';
import InovateBox from '../components/Inovate/InovateBox'
import OurTeamBox from '../components/OurTeam/OurTeamBox'

export default function AboutUs({setVisible,executeScroll}) {

  let aboutBody = document.getElementsByClassName("about_body")[0]
  const [prevScrollpos, setPrevScrollPos] = useState(0);
  const [readMore,setReadMore]=useState(false);
  const readMoree=()=>{
    setReadMore(true);
  }
  const readLess=()=>{
    setReadMore(false);
  }
  const teamRef = useRef(null)
  
  useEffect(() => {
    if (!aboutBody) {
      aboutBody = document.getElementsByClassName("about_body")[0]
    }
    aboutBody?.addEventListener('scroll',()=>handleScroll(aboutBody,prevScrollpos,setVisible,setPrevScrollPos));
    return () => aboutBody?.removeEventListener('scroll',()=>handleScroll(aboutBody,prevScrollpos,setVisible,setPrevScrollPos))
  },[aboutBody?.scrollTop])
  
  useEffect(() => {
    setVisible(true)
  }, [])
  return (
    <div className=" h-screen about_body absolute">
      <div style={{ "backgroundImage": 'url("about-us-pattern.png")' }} className="mx-4 pt-24 snap-start aboutUsHero bg-contain h-screen bg-no-repeat msm:bg-right bg-center mvsm:mx-6 md:mx-20 msm:mx-10">

        <h1 className="text-2xl pt-4 vsm:text-3xl sm:text-4xl mvsm:text-5xl md:text-7xl  xl:text-8xl text-secondary-2 font-black">
          We create digital experiences
        </h1>
        {/* <p className="text-xl sm:text-2xl text-secondary-2 font-extrabold my-4 mvsm:my-7">
        <ReactTypingEffect
        speed={100}
        eraseSpeed={100}
        eraseDelay={2000}
        typingDelay={2000}
        cursor="_"
            text={["Innovate", "Automate", "Acclerate"]}

          />
        </p> */}
        <p className='font-semibold my-4 pt-4 msm:w-[35vw]'>
        We are a leading provider of customized software solutions designed to meet the specific needs of our clients.
        </p>
        
        <div onClick={() => {
                executeScroll(teamRef);
              }} className=" border-2 border-secondary-2 rounded-full h-12 w-7 cursor-pointer left-1/2 -translate-x-1/2 absolute bottom-10 lg:mt-0" >
          <div className="border bg-secondary-2 border-secondary-2 h-2 rounded-full mx-auto w-fit mt-2 animate-curser_upDown">
            
          </div>
        </div>
      </div>
      

      <div className=" snap-start px-4 mvsm:px-6 md:px-20 msm:px-10 bg-secondary-2  flex flex-col items-center py-16 gap-5">
      <h4 className="text-Primary-Colour tracking-widest font-semibold ">
      Our Story
        </h4>
        {/* <h2 className="text-white text-center text-2xl msm:text-3xl md:text-4xl lg:text-6xl font-extrabold aboutUs_heading">
         
        Our Story
        </h2> */}
        <p className="text-white mvsm:font-medium font-[500] text-center about_us_para">
        Red Dot Innovative is founded on the belief that every business is unique and deserves tailored solutions that fit their specific needs. Our company develops high-quality software applications for businesses of all sizes and industries. We specialise in creating bespoke digital solutions tailored to our client's unique requirements and specifications by working closely with them. Headquartered in Singapore and operating internationally, our team drives digital transformation for businesses globally. Our team consists of in-house developers, designers, project managers, Quality Engineers and customer success managers who work together using the latest technologies and development practices to ensure that our clients receive cutting-edge solutions that are scalable and robust.

We understand the importance of reliable and efficient software systems and are committed to ensuring that our clients get the most out of their software applications and help them stay ahead of the competition. 
{/* {!readMore?<div className="text-Primary-Colour" onClick={readMoree}>Read More....</div>:""} {readMore?<p>
 We work closely with our clients to understand their needs, goals, and challenges and develop software solutions that meet their specific requirements.

We offer a wide range of software development services, including software design, development, testing, deployment, and maintenance. Our software development process is designed to be transparent, collaborative, and efficient, ensuring that our clients are involved in every step of the development process.

In addition to custom software development, we also provide consulting services to help our clients optimize their software systems and improve their business processes. Our experienced consultants can help you identify areas of improvement and provide recommendations for implementing software solutions that can help you achieve your business goals.

At our custom software company, we are committed to providing our clients with exceptional service and support. We understand the importance of reliable and efficient software systems, and we work hard to ensure that our clients get the most out of their software applications.

Thank you for considering our custom software company for your software needs. We look forward to working with you and providing you with innovative, customized, and high-quality software solutions.{" "}
</p>:""}   
{readMore?<div className="text-Primary-Colour" onClick={readLess}>Read Less</div>:""}  */}
</p>

      </div>

      {/* <div ref={teamRef} className="px-4 snap-start mvsm:px-6 md:px-20 msm:px-10 bg-white flex flex-col items-center py-8 gap-5">
        <h4 className="text-Primary-Colour text-center tracking-widest font-semibold ">
          THE GENIUSES BEHIND OUR WORKS
        </h4> 
        <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-extrabold">
          Our Team
        </h2>
        <div className='flex gap-7 justify-center'>
          <OurTeamBox phone={true} name='JOHN SMITH' image='unsplash_DItYlc26zVI.png' intro='Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy ' position='FOUNDER' />
          <OurTeamBox phone={null} name='JOHN SMITH' image='unsplash_DItYlc26zVI.png' intro='Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy text.Loreum ipsum is dummy ' position='FOUNDER' />
        </div>
      </div> */}
      <div className="snap-start py-8 px-4 mvsm:px-6 md:px-20 msm:px-10 bg-white flex flex-col items-center gap-5">
        <h4 className="text-Primary-Colour tracking-widest font-semibold ">
          HOW WE INNOVATE
        </h4>
        <h2 className="text-secondary-2 text-center text-2xl msm:text-3xl md:text-5xl lg:text-6xl font-extrabold">
          The Innovation Journey
        </h2>
        <div className='flex flex-wrap mt-8 justify-center items-start gap-10'>
          <InovateBox image='consulatation.svg' title='Consultation' description='By focusing on core business functionalities, we meet our clients to empathise and understand their problems and requirements. We then make recommendations for best practices followed by the market for digital products that would be effective and efficient for the client. ' />
          <InovateBox image='business-analysis.svg' title='Business Analysis' description='Competition is at an all-time high in the market across all industries. We do a deep analysis of the client and their industry to mobilise innovation and technology to help businesses stand out. By zeroing down on exact customer requirements, we prepare a perfect timeline for the execution of suggested inputs in a phased manner. This process has always resulted in tremendous benefits to businesses.' />
          <InovateBox image='design-and-dev.svg' title='Design & Developement' description='Uniquely crafted designs by our expert UI/UX team, (certified by institutes like Harvard and Stanford) use Design Thinking principles with cutting-edge technological solutions to deliver a smooth and easy experience for end users. Based on the finalised design, the project is developed in a waterfall approach, with regular demos scheduled for our client’s ease of mind. ' />
          <InovateBox image='support.svg' title='Support & Maintenance' description='Maintenance of systems is important to help businesses stay relevant, irrespective of any market turmoil. We guarantee that clients get the highest quality products by providing rigorous quality checks before projects are handed over to our clients.' />
          <InovateBox image='go-live.svg' title='Transition & Go Live' description='Our tireless commitment to delivering the best of design and technology into actionable businesses sets us apart. Our track record speaks for itself – flawless execution, problem-solving, and an eye for innovation. Discover how we turn concepts into reality and elevate the way businesses operate and grow.'/>
        </div>
      </div>
      <div className="snap-start">
        <Footer />
      </div>
      <div className="snap-start">
        <FooterBottom />
      </div>
    </div>
  )
}
