import React, { useEffect, useRef, useState } from 'react'
import { Outlet } from 'react-router-dom';
import ReactTypingEffect from 'react-typing-effect';
import Footer from '../components/Footer/Footer';
import FooterBottom from '../components/Footer/FooterBottom';
import { handleScroll } from '../components/Helper/Helper';
import IndustriesBox from '../components/Industries/IndustriesBox';
import IndustriesInner from '../components/Industries/IndustriesInner';
import ServiceInner from '../components/Services/ServiceInner';

export default function Services({setVisible,executeScroll}) {

  const foodRef = useRef(null)
  let serviceBody = document.getElementsByClassName("service_body")[0]
  const [prevScrollpos, setPrevScrollPos] = useState(0);
  useEffect(() => {
    
    window.scrollTo(0, 0);
  
 
}, []);
  useEffect(() => {
    if (!serviceBody) {
      serviceBody = document.getElementsByClassName("service_body")[0]
    }
    serviceBody?.addEventListener('scroll',()=>handleScroll(serviceBody,prevScrollpos,setVisible,setPrevScrollPos));
    return () => serviceBody?.removeEventListener('scroll',()=>handleScroll(serviceBody,prevScrollpos,setVisible,setPrevScrollPos))
  },[serviceBody?.scrollTop])
  
  useEffect(() => {
    setVisible(true)
  }, [])

  return (
    <div className="h-screen w-full t-0 service_body absolute">
      <div style={{ "backgroundImage": 'url("about-us-pattern.png")' }} className="mx-4 pt-24 snap-start aboutUsHero bg-contain h-screen bg-no-repeat msm:bg-right bg-center mvsm:mx-6 md:mx-20 msm:mx-10">
        <h1 className="text-2xl mt-4  vsm:text-3xl sm:text-4xl mvsm:text-5xl md:text-7xl  xl:text-8xl text-text/heading font-black">
          Redefine industries <br /> through innovation 
        </h1>
        {/* <p className='mt-14 text-text/heading font-normal text-xl'>
          Logistics, food & beverages, enterprise solutions, <br /> lifestyle, healthcare and social, we innovate it all
        </p> */}
        <div onClick={() => {
          executeScroll(foodRef);
        }} className=" border-2 border-secondary-2 rounded-full h-12 w-7 cursor-pointer left-1/2 -translate-x-1/2 absolute bottom-10 lg:mt-10" >
          <div className="border bg-secondary-2 border-secondary-2 h-2 rounded-full mx-auto w-fit mt-2 animate-curser_upDown">
          </div>
        </div>
      </div>
      <ServiceInner foodRef={foodRef}/>
      <div className="snap-start">
        <Footer />
      </div>
      <div className="snap-start">
        <FooterBottom />
      </div>
    </div>
  )
}
